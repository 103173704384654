import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon/styles';
import { CheckboxInputProps } from './types';
import { CheckboxContainer } from './styles';


const CheckboxInput:React.FC<CheckboxInputProps> = ({ value, callback }) => {
    const [checked, setChecked] = useState<boolean>(value ?? false);
    const ICON_PATH = `${process.env.PUBLIC_URL}/assets/check.png`;

    useEffect(() => {
        value && setChecked(value)
    }, [value])

    const handleClick = (e) => {
        e.stopPropagation();
        setChecked(!checked);
        callback && callback(!checked);
    }


    return (
        <CheckboxContainer $checked={checked} onClick={handleClick}>
            {checked && <Icon src={ICON_PATH} alt="checkmark"/>}
        </CheckboxContainer>
    )
}


export default CheckboxInput;