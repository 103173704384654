import styled from "styled-components";
import COLORS from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { AnalysisHeading } from "./types";

export const DocumentViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
`;

export const PdfWrapper = styled.div`
  flex-grow: 1;
  border-right: 1px solid ${COLORS.UI.BORDER.STANDARD};
  display: flex;
  flex-direction: column;
  padding: ${sizing.SPACING.STANDARD};
`;

export const PdfPaginationControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;

  & :first-child {
    justify-content: flex-start;
  }

  & :last-child {
    justify-content: flex-end;
  }
`;

export const PdfDataWrapper = styled(PdfWrapper)`
  flex: 1;
  width: min-content;
  min-width: 400px;
  max-width: 500px;
  height: 100%;
  overflow-y: scroll;
  padding: 0;
`;

export const ExampleDocument = styled.div`
  width: 500px;
  height: 1500px;
  background-color: #dddddd;
`;

export const PdfColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding-bottom: ${sizing.SPACING.STANDARD};
  border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};
  padding: ${sizing.SPACING.STANDARD};
`;

export const AnalysisWrapper = styled.div`
  padding: ${sizing.SPACING.STANDARD};
`;

export const IFrame = styled.iframe`
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  button {
    margin: ${sizing.SPACING.SMALLEST};
  }
`;

export const AnalysisContainer = styled.div`
  width: 100%;
  margin: ${sizing.SPACING.STANDARD} 0;
  border: 1px solid ${COLORS.UI.BORDER.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  p {
    padding: ${sizing.SPACING.STANDARD};
    margin: 0;
  }

  & :last-child {
    border: none;
  }
`;

export const Heading = styled.p<AnalysisHeading>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: ${sizing.RADIUS.MEDIUM} ${sizing.RADIUS.MEDIUM} 0 0;
  border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};
  background-color: ${({ selected }) =>
    selected ? COLORS.UI.BACKGROUND.ACTIVE : COLORS.UI.BACKGROUND.ACCENT};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.UI.BACKGROUND.SELECTED};
  }

  transition: background-color 250ms ease;
`;

export const KeyWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};

  & > :first-child {
    min-width: 30px;
  }

  & > :last-child {
    margin-right: ${sizing.SPACING.STANDARD};
  }
`;
