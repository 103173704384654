export {};

export enum DocumentType {
    COMPARISON = 'Comparison',
    POLICY = 'Policy',
    PROPOSAL = 'Proposal',
}

export enum DocumentStatus {
    PROCESSED = 'Processed',
    PROCESSING = 'Processing...',
    DOCUMENT = 'document',
}

export interface DocumentResponse {
    documents: DocumentDTO[];
}

//TODO need to confirm this 
export interface DocumentDTO {
    "_id": string,
    "associated_document_id": any,
    "category": string,
    "client_id": string,
    "coverage_lines": string[],
    "created_at": string,
    "display_name": string,
    "doc_type": string,
    "document_instance_id": string,
    "file_key": string,
    "name": string,
    "percent_verified": number,
    "policy_type": string,
    "s3_location": string,
    "status": DocumentStatus,
    "user_id": string,
    "web_link": string,
}
// TEST CHANGE
export interface Document {
    id: string;
    category: string;
    instanceId?: string;
    associatedDocumentId?: string;
    name: string;
    displayName: string;
    link: string;
    status: DocumentStatus;
    percentComplete: number;
    percentVerified?: number;
    createdAt?: string;
    clientId?: string;
    lines?: string[];
    propertyId?: string;
    property?: string;
    propertyRequired?: boolean;
    instanceIds?: string[];
    reportStatus?: DocumentStatus; // status
    reportLink?: string; // report link in s3
    reportInitiated?: string; // so i can display (3m remaining)
    isReport?: boolean;
  }

  export interface CreateProposalResponse {
    "proposal_id": string,
  }

  export interface UploadDocumentResponse {
    
  }
  