import { size } from "lodash";
import styled from "styled-components";
import sizing from "../../designSystem/sizing";

export const SchedulesExceptionDisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  size: ${sizing.TEXT.H2};
`;

export const ButtonWrapper = styled.div`
  & button {
    margin: auto auto ${sizing.SPACING.QUAD_LARGE} auto;

    & span {
      font-weight: 400;
    }
  }
`;

export const Message = styled.h2`
  margin: ${sizing.SPACING.QUAD_LARGE} ${sizing.SPACING.DOUBLE_LARGE}
    ${sizing.SPACING.DOUBLE_LARGE};
`;
