import { Pages } from "../../LandingPage/types";
import { Client } from "../../types/Client";
import { DocumentType } from "../Document";

export interface NavbarProps {
  clientId: string;
  setClientId: (s: string) => void;
  clients: Client[];
  setCreateClientModalOpen: (b: boolean) => void;
  setSettingsModalOpen: (b: boolean) => void;
  onSearch?: (term: string) => void;
  onHome: () => void;
  document?: DocumentType;
  secondDocument?: DocumentType;
  handleFileUpload: (
    file: any,
    category: "policy" | "endorsement",
    associatedDocumentId?: string
  ) => Promise<any>;
  handleRegenerateComparison?: () => void;
  retrieveDocuments?: () => void;
  page?: Pages;
  handleDelete?: (doc: DocumentType) => void;
}

export enum UploadActions {
  UPLOAD = "upload",
  UPLOAD_ENDORSEMENT = "upload_endorsement",
}
