import styled from "styled-components";
import sizing from "../../designSystem/sizing";
import { TabStyleProps } from "./types";
import colors from "../../designSystem/colors";

export const TabulatedSelectionContainer = styled.div`
  display: flex;
`;

export const Tab = styled.div<TabStyleProps>`
  padding: ${sizing.SPACING.SMALL} ${sizing.SPACING.LARGE};
  border-bottom: 1px solid
    ${({ selected }) =>
      selected ? colors.UI.BORDER.SELECTED : colors.UI.BORDER.STANDARD};
  transition: border-bottom 0.2s ease;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${colors.UI.BORDER.HIGHLIGHT};
  }

  & span {
    color: ${({ selected }) =>
      selected
        ? colors.UI.TEXT.STANDARD
        : colors.UI.TEXT.EXTRA_LIGHT} !important;

    transition: color 0.2s ease;
  }
`;

export const TabFiller = styled.div`
  border-bottom: 1px solid ${colors.UI.BORDER.STANDARD};
  flex-grow: 1;
`;
