import styled from "styled-components";
import SIZING from "../../designSystem/sizing"


export const PaginationWrapper = styled.div`
    display: flex;
    padding: ${SIZING.SPACING.STANDARD};
    align-items: center;
    justify-content: space-between;
`;

export const PaginationButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 34px;
`;