import styled from "styled-components";
import COLORS from "../../designSystem/colors";
import SIZING from "../../designSystem/sizing";

export const NavbarContainer = styled.div`
  position: "fixed";
  width: "100%";
  top: 0;
  left: 0;
  background-color: ${COLORS.UI.BACKGROUND.STANDARD};
  display: flex;
  padding: ${SIZING.SPACING.LARGE};
  align-items: center;
  justify-content: space-between;
`;

const NavbarPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  > * {
    height: 36px !important;
  }
`;

export const NavbarLeft = styled(NavbarPositionWrapper)`
  justify-content: flex-start;
  align-items: center;
`;
export const NavbarCenter = styled(NavbarPositionWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 40px !important;
  }
`;

export const NavbarTitleEdit = styled(NavbarPositionWrapper)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const NavbarRight = styled(NavbarPositionWrapper)`
  justify-content: flex-end;
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  > * {
    height: 36px !important;
    margin-right: ${SIZING.SPACING.STANDARD};
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const EditableTextWrapper = styled.div`
  display: block;
  width: 350px;
  & * {
    text-align: right;
  }
`;
