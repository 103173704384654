import React, { useEffect, useRef, useState } from 'react';

import { Header, SubHeader } from '../../Header/styles';
import Search from '../../../assets/icons/Search.svg';
import Upload from "../../../assets/icons/Upload.svg";
import TextInput from '../../TextInput';
import Text from '../../Text';
import { SelectFirstPolicyProps } from '../types';
import { ButtonWrapper, ComparisonWrapper, HeaderWrapper, ProgressDisplay, SearchWrapper, SelectedDocumentsWrapper, SelectionWrapper } from '../styles';
import DocumentWizardTable from '../../DocumentWizardTable';
import Button from '../../Button';
import Close from '../../../assets/icons/x-close.svg'
import CloseActive from '../../../assets/icons/Close_Active.svg'
import FileActive from '../../../assets/icons/File_active.svg'
import COLORS from '../../../designSystem/colors';
import ChevronRight from '../../../assets/icons/ChevronRight.svg';
import ChevronDown from '../../../assets/icons/ChevronDown.svg';
import { DocumentType } from '../../Document';
import { InitialWrapper, TableContainer } from '../../DocumentTable/styles';
import { HiddenInput } from '../../Navbar/styles';
import { UploadActions } from '../../Navbar/types';

const SelectFirstPolicy: React.FC<SelectFirstPolicyProps> = ({ rows, headers, handleSelect, handleFileUpload, handleContinue, handleCancel, progressBlocked, selected, handleRemoveFromSelected, lastUpload }) => {
    const [showSelected, setShowSelected] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentType[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [uploadedId, setUploadedId] = useState<string | null>(null);

    const handleSearch = (term: string) => setSearchTerm(term);

    const toggleShowSelected = () => {
        setShowSelected((previous) => !previous);
    }

    useEffect(() => {
        if (rows) {
          const filteredList = rows.filter(document => document.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredDocuments(filteredList);
          }
      }, [searchTerm, rows]);

    // <Text content='You can choose as many documents associated with this policy.'/>


    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
        };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);

        const files = event.dataTransfer.files;
        Object.keys(files).forEach(async (key) => {
            const newId = await handleFileUpload(files[key], "policy", undefined);
            setUploadedId(newId);
        });
    };


    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        uploadAction: UploadActions
    ) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            Object.keys(files).forEach(async (key) => {
                const newId = await handleFileUpload(files[key], "policy", undefined);
                setUploadedId(newId);
            });
        }
    };

    useEffect(() => {
        if (uploadedId) {
            const lastUploaded = rows.find(r => r.instanceId == uploadedId);
            lastUploaded && handleSelect([lastUploaded]);
            setUploadedId(null);
        }
    }, [uploadedId])

    return(
        <>
            <HeaderWrapper>
                <ProgressDisplay>
                    <Text content="COMPARE POLICIES" />
                </ProgressDisplay>
                <Button iconLeft={Close} handleClick={handleCancel} $borderless/>
            </HeaderWrapper>
            <SubHeader>Select Policies to Compare</SubHeader>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
                <div style={{ display: "flex", flexDirection: "column"}}>
                    <SearchWrapper>
                        <TextInput icon={Search} $grow placeholder='Search...' onChangeCallback={handleSearch}/>
                    </SearchWrapper>
                    <DocumentWizardTable headers={headers} rows={filteredDocuments} onSelect={handleSelect} singleSelect $width="calc(35vw - 30px)"/>
                </div>
                — or —
                <TableContainer
                    isDragging={isDragging}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    $height="420px"
                    $marginTop="20px"
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "30px", height: "100%"}}>
                        <div style={{ fontSize: "25px", fontWeight: "600" }}>Upload a Policy:</div>
                        <Button
                            iconLeft={Upload}
                            text="Upload"
                            handleClick={handleUpload}
                            $large
                        />
                        <HiddenInput
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => handleFileChange(e, UploadActions.UPLOAD)}
                        />
                    </div>
                </TableContainer>
            </div>

            {selected[0] && (
                <SelectedDocumentsWrapper>
                    {selected.map((document, index) => {
                        return (
                            <Button
                                key={index}
                                handleClick={() => handleRemoveFromSelected(document)}
                                iconLeft={FileActive}
                                text={document.name}
                                iconRight={CloseActive}
                                $color={COLORS.UI.BORDER.SELECTED}
                                $background={COLORS.UI.BACKGROUND.SELECTED} />
                        );
                    })}    
                </SelectedDocumentsWrapper>
            )}

            <ButtonWrapper>
                <Button text="Cancel" handleClick={handleCancel}/>
                <Button disabled={progressBlocked} text="Continue" handleClick={handleContinue} $secondary/>
            </ButtonWrapper>
        </>
    );
}

export default SelectFirstPolicy;