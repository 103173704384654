import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";

export const ComparedValuesContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  justify-content: flex-end;
  max-width: 50vw;
  @media (max-width: 1800px) {
    max-width: 400px;
  }

  @media (max-width: 1400px) {
    max-width: 300px;
  }

  @media (max-width: 1250px) {
    max-width: 200px;
  }
`;

const BorderedText = styled.span`
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  background-color: ${colors.UI.BACKGROUND.STANDARD};
  padding: ${sizing.SPACING.SMALL} ${sizing.SPACING.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  flex: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min-content;
`;

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  margin-right: ${sizing.SPACING.STANDARD};
  background-color: ${colors.UI.BORDER.STANDARD};
`;

export const FirstValueContainer = styled(BorderedText)`
  margin-right: ${sizing.SPACING.STANDARD};
`;

export const SecondValueContainer = styled(BorderedText)``;
