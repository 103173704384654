import React, { useEffect, useState } from "react";
import { SchedulesExceptionDisclaimerProps } from "./types";
import {
  ButtonWrapper,
  Message,
  SchedulesExceptionDisclaimerContainer,
} from "./styles";
import DownloadWhite from "../../assets/icons/DownloadWhite.svg";
import Button from "../Button";

const SchedulesExceptionDisclaimer: React.FC<
  SchedulesExceptionDisclaimerProps
> = ({ handleClick, generating }) => {
    const [periods, setPeriods] = useState<string>('');

    useEffect(() => {
      if (generating) {
        const interval = setInterval(() => {
          setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
        }, 500);
    
        return () => clearInterval(interval);
      }
    }, [generating]);

  return (
    <SchedulesExceptionDisclaimerContainer>
      <Message>
        We do currently don’t support viewing schedules in app, please download
        the excel to view
      </Message>
      <ButtonWrapper>
        <Button
          text={ generating ? `Generating Excel${periods}` : "Download Excel" }
          iconLeft={DownloadWhite}
          $secondary
          handleClick={handleClick}
          disabled={!!generating}
        />
      </ButtonWrapper>
    </SchedulesExceptionDisclaimerContainer>
  );
};

export default SchedulesExceptionDisclaimer;
