import { DocumentType, DocumentStatus } from "../../types/DocumentTemporary";

export enum PillType {
    COMPARISON = 'comparison',
    POLICY = 'policy',
    PROPOSAL = 'proposal',
    ALL = 'all',

    UPLOADING = "uploading",
    UPLOADED = "uploaded",
    ANALYZING = "analyzing",
    ANALYZING_POLICY = "analyzing_policy",
    ANALYZED = "analyzed",
    GENERATED = "generated",
    FAILED = "failed"
}

export enum PillTypeIcon {
    UPLOADING = '',
    UPLOADED = '',
    ANALYZING = '',
    ANALYZING_POLICY = '',
    GENERATED = '',
    PROCESSED = '',
    PROCESSING = '',
    DOCUMENT = '',
}

export interface PillColor {
    border: string;
    background: string;
    text: string;
}

export interface PillProps {
    type: string;
    onClick?: any;
    pillText?: string;
    $grow?: boolean;
    icon?: string;
    $greyscale?: boolean;
    $nowrap?: boolean;
}

export type MappableType = DocumentType | DocumentStatus;