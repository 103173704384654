
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import introJs from 'intro.js';

import { DashboardProps } from './types';
import { PillType } from '../../components/Pill/types';
import Modal from '../../components/Modal';
import CreateProposal from '../../components/CreateProposal';
import DocumentTable from '../../components/DocumentTable';
import AppHeader from '../../components/Navbar';
import PolicyComparisonWizard from '../../components/PolicyComparisonWizard';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { PageContentWrapper, PageContentHorizontalWrapper } from './styles';
import { filterAndMapToProposals } from './utils';
import FileText from '../../assets/icons/FileText.svg';
import ProposalsIcon from '../../assets/icons/Proposals.svg';
import AllDocuments from '../../assets/icons/AllDocuments.svg';
import Files from '../../assets/icons/Files.svg';
import { DocumentType } from '../../components/Document';
import { PolicyComparisonWizardContainer } from '../../components/PolicyComparisonWizard/styles';
import SelectComparison from '../../components/PolicyComparisonWizard/pages/SelectComparison';
import { analyzePolicy, markWalkthroughCompleted, updateCoverageLines } from '../../requests';
import { dashboardWT } from '../../walkthroughs/dashboard';

const Dashboard: React.FC<DashboardProps> = ({ user, docList, reports, retrieveDocuments, clientId, clients = [], setClientId, setCreateClientModalOpen, setSettingsModalOpen, onHome, onDocumentView, createProposalModalOpen, setCreateProposalModalOpen, comparePolicyModalOpen, setComparePolicyModalOpen, isLoading, onCreateProposal, onCreateComparison, cookie, handleFileUpload, lastUpload, addLinesModalOpen, setAddLinesModalOpen, retrieveUserInfo, agencyId }) => {

    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentType[] | undefined>([]);
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filter, setFilter] = useState<PillType | undefined>(PillType.ALL);
    const [loading, setLoading] = useState<boolean>(false);
    const [homepageWalkthroughDone, setHomepageWalkthroughDone] = useState<boolean>(false);
    
    const sideBarOptions = [
      {
        handeClick: () => setFilter(PillType.ALL),
        text: "All",
        icon: AllDocuments,
        type: PillType.ALL
      },
      {
          handeClick: () => setFilter(PillType.POLICY),
          text: "Policies",
          icon: FileText,
          type: PillType.POLICY
      },
      {
          handeClick: () => setFilter(PillType.PROPOSAL),
          text: "Proposals",
          icon: ProposalsIcon,
          type: PillType.PROPOSAL
      },
      {
          handeClick: () => setFilter(PillType.COMPARISON),
          text: "Comparisons",
          icon: Files,
          type: PillType.COMPARISON
      },
    ];


  const startTour = async () => {
      const intro = introJs();

      intro.setOptions({
        steps: dashboardWT,
        showStepNumbers: true,
        exitOnOverlayClick: false,
      });

      async function onWTComplete() {
        await markWalkthroughCompleted(cookies['user-id'], "homepage");
        setHomepageWalkthroughDone(true);
        retrieveUserInfo();
      }

      intro.oncomplete(() => {
        onWTComplete();
        
      });

      intro.onexit(() => {
        onWTComplete();
      });

      intro.start();
    };

    useEffect(() => {
      if (user && !user.homepage_wt_completed && !homepageWalkthroughDone) {
        startTour();
      }
    }, [user])


    useEffect(() => {
      if (docList) {
        const filteredByType = (filter && filter != PillType.ALL) ? docList.filter(document => {
          return filter ? document.category === filter.toLocaleLowerCase() : false
        }) : docList.filter(document => document.category !== "endorsement");
        const filteredList = filteredByType.filter(document => document.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredDocuments(filteredList);
        }
    }, [searchTerm, docList, filter]);
    
      const handleCreateProposalModalClose = () => { setCreateProposalModalOpen(false); }
      const handleComparePolicyModalClose = () => { setComparePolicyModalOpen(false); }
      const handleSearch = (term: string) => setSearchTerm(term);
      const handleSelectLines = async (selected: string[]) => {
        setLoading(true);
        await updateCoverageLines(cookies['user-id'], lastUpload ?? "", selected);
        await analyzePolicy(cookies['user-id'], "", lastUpload ?? "", "internal", true);
        retrieveDocuments();
        setAddLinesModalOpen(false);
        setLoading(false);
    };

      return (
        <>
          <AppHeader
            clientId={clientId}
            setClientId={setClientId}
            clients={clients}
            setCreateClientModalOpen={setCreateClientModalOpen}
            setSettingsModalOpen={setSettingsModalOpen}
            onSearch={handleSearch}
            onHome={onHome}
            handleFileUpload={handleFileUpload}
          />
          <TopBar 
            handleFilter={() => setSidebarOpen(!sidebarOpen)}
            handleComparePolicies={() => setComparePolicyModalOpen(true)}
            handleCreateProposal={() => setCreateProposalModalOpen(true)}
          />
          <PageContentWrapper sidebarOpen={sidebarOpen}>
            <PageContentHorizontalWrapper>
              <SideBar options={sideBarOptions} selected={filter}/>
              <DocumentTable docList={docList} reports={reports} documents={filteredDocuments} retrieveDocuments={retrieveDocuments} selectedDocuments={selectedDocuments} setSelectedDocuments={setSelectedDocuments} onDocumentView={onDocumentView} isLoading={isLoading} cookie={cookie} handleFileUpload={handleFileUpload} filtered={!!filter || searchTerm != ""}/>
            </PageContentHorizontalWrapper>
          </PageContentWrapper>
    
          {createProposalModalOpen &&
            <Modal onClick={handleCreateProposalModalClose}>
              <CreateProposal alreadySelected={selectedDocuments} proposals={filterAndMapToProposals(docList || [], PillType.POLICY)} close={handleCreateProposalModalClose} onSubmit={onCreateProposal} handleFileUpload={handleFileUpload} agencyId={agencyId}/>
            </Modal>
          }
    
          {comparePolicyModalOpen &&
            <Modal onClick={handleComparePolicyModalClose}>
              <PolicyComparisonWizard handleFileUpload={handleFileUpload} alreadySelected={selectedDocuments} rows={filterAndMapToProposals(docList || [], PillType.POLICY)} handleCancel={handleComparePolicyModalClose} onSubmit={onCreateComparison} lastUpload={lastUpload}/>
            </Modal>
          }

          {addLinesModalOpen &&
            <Modal onClick={() => {}}>
              <PolicyComparisonWizardContainer>
                <SelectComparison policyName={docList?.find(d => d.instanceId == lastUpload)?.name ?? undefined} onComplete={handleSelectLines} loading={loading} singlePolicy />
              </PolicyComparisonWizardContainer>
            </Modal>
          }
        </>
      )
}

export default Dashboard;