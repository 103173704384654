import { Client, ClientDTO, ClientsResponse } from "../types/Client";
import { ApiClient } from "./apiClient";

const apiClient = new ApiClient(process.env.REACT_APP_BE_URL || "");

const getClients = async (cookie: string): Promise<Client[]> => {
    const res: ClientsResponse = await apiClient.get('/retrieve-clients', {
        'auth-token': cookie
    });

    return res.clients.map(clientDTO => mapClientDTOToClient(clientDTO)) as Client[];
};

export default { getClients };

export const mapClientDTOToClient = (dto: ClientDTO): Client => {
    return {
        id: dto._id,
        clientId: dto.client_id,
        name: dto.name,
        userd: dto.user_id,
        currentClientId: dto.current_client_id
    };
  };