import { styled } from "styled-components";
import sizing from "../../designSystem/sizing";
import colors from "../../designSystem/colors";
import { DocumentTitleIconProps } from "./types";

export const ComparisonPageContainer = styled.div`
  display: block;
  margin: 0;
  padding: 0;
`;

export const Description = styled.p`
  margin: 0 0 ${sizing.SPACING.STANDARD} 0;
`;

export const ComparisonsContainer = styled.div`
  display: flex;
  margin: 0 ${sizing.SPACING.QUAD_LARGE};
  align-items: center;
  overflow: hidden;
`;

export const ComparisonsWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const HeadedContentContainer = styled.div`
  margin: ${sizing.SPACING.STANDARD} 0;
  width: 100%;
  // min-width: 0;
`;

export const HeadedContent = styled.div`
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
`;

export const HeadedContentHeader = styled.h2`
  font-size: ${sizing.TEXT.H2};
  font-weight: 500;
  margin: 0;
`;

export const HeadedContentSubHeader = styled.p`
  color: ${colors.UI.TEXT.GREY};
  margin: 0 0 ${sizing.SPACING.SMALL} 0;
`;

export const EnlargedHeadedContentSubHeader = styled(HeadedContentSubHeader)`
  font-size: ${sizing.TEXT.H2};
`;

export const HeadedContentContent = styled.p`
  margin: 0;
`;

export const EnlargedHeadedContentContent = styled(HeadedContentContent)`
  font-size: ${sizing.TEXT.H2};
`;

export const HeadedContentHeaderWrapper = styled.div`
  padding: ${sizing.SPACING.STANDARD};
  border-bottom: 1px solid ${colors.UI.BORDER.STANDARD};
`;

export const HeadedContentContentWrapper = styled.div`
  padding: ${sizing.SPACING.STANDARD};
`;

export const FilterComparisonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const FilterContainer = styled.div`
  margin-right: ${sizing.SPACING.STANDARD};
`;

export const ComparisonDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

export const QuickFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: ${sizing.SPACING.STANDARD};
  }
`;

export const DocumentComparisonContainer = styled.div``;

export const DocumentTitleIcon = styled.div<DocumentTitleIconProps>`
  display: flex;
  align-items: baseline;
  & :first-child {
    margin-right: ${sizing.SPACING.SMALLEST};
  }

  ${({ $displayMargin }) =>
    $displayMargin ? `margin-bottom: ${sizing.SPACING.STANDARD}` : ""};
`;

export const CoverageTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

export const ComparisonCaratContainer = styled.div`
  display: flex;
  align-items: center;
  & > :last-child {
    margin-left: ${sizing.SPACING.STANDARD};
  }
`;

export const LabelledEditableWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    width: 100px;
    margin-right: ${sizing.SPACING.STANDARD};
  }
`;

export const EditableFields = styled.div`
  & > div {
    margin-bottom: ${sizing.SPACING.STANDARD};
  }

  & > :first-child {
    margin-top: ${sizing.SPACING.STANDARD};
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
