import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ChildRef, TabulatedSelectionProps } from "./types";
import { TabulatedSelectionContainer, Tab, TabFiller } from "./styles";

const TabulatedSelection = forwardRef<ChildRef, TabulatedSelectionProps>(
  ({ options, handleSelection }, childRef) => {
    const [selected, setSelected] = useState<number>(0);

    const handleClick = (index: number) => {
      setSelected(index);
      handleSelection(index);
    };

    useImperativeHandle(childRef, () => ({
      resetIndex: () => {
        setSelected(0);
      },
    }));

    const renderOptions = () => {
      const mappedItems = options.map((option, index) => (
        <Tab
          key={index}
          selected={selected === index}
          onClick={() => handleClick(index)}
        >
          {option}
        </Tab>
      ));

      mappedItems.push(<TabFiller />);
      return mappedItems;
    };

    return (
      <TabulatedSelectionContainer>
        {renderOptions()}
      </TabulatedSelectionContainer>
    );
  }
);

export default TabulatedSelection;
