import React, { useEffect, useRef, useState } from "react";
import { DocumentTableProps } from "./types";
import {
  BodyTr,
  DragInfoCard,
  DragInfoContainer,
  DragInfoHeader,
  DragInfoSubHeader,
  DragInfoTextWrapper,
  InitialWrapper,
  LinkedContainer,
  LinkedTd,
  NameTd,
  SpinnerWrapper,
  StatusTd,
  Table,
  TableContainer,
  TBody,
  Td,
  Th,
  ThDocDescription,
  THead,
  ThType,
  ToastText,
  Tr,
  VerifiedTd,
} from "./styles";
import CheckboxInput from "../CheckboxInput";
import DocumentDescription from "../DocumentDescription";
import Pill from "../Pill";
import Dropdown from "../Dropdown";
import Ellipsis from "../../assets/icons/Ellipsis.svg";
import FileInput from "../../assets/icons/FileInput.svg";
import Upload from "../../assets/icons/Upload.svg";
import Download from "../../assets/icons/Download.svg";
import Trash from "../../assets/icons/Trash.svg";
import { DocumentType } from "../../components/Document";
import { DocumentType as DocType } from "../../types/DocumentTemporary";
import { PillType } from "../Pill/types";
import LoadingSpinner from "../base/Spinner";
import { Header, SubHeader } from "../Header/styles";
import { Icon } from "../Icon/styles";
import File2 from "../../assets/icons/File2.svg";
import { deleteDocument } from "../../requests";
import { handleDownload } from "../../utils";
import Toast from "../Toast";
import Button from "../Button";
import { CircularProgressWithLabel } from "../CircularProgressWithLabel";
import { HiddenInput } from "../Navbar/styles";
import { UploadActions } from "../Navbar/types";
import Space from "../base/Space";

export const LinkedDocuments = ({ row, documents, onDocumentView, showAll}: {
  row: DocumentType | null,
  documents: DocumentType[] | undefined,
  onDocumentView: (doc: DocumentType) => void,
  showAll?: boolean
}) => {
  const [show, setShow] = useState<boolean>(!!showAll);

  useEffect(() => {
    setShow(!!showAll);
  }, [showAll])

  let linkedDocuments: DocumentType[] = [];
  if (row?.category == "comparison" || row?.category == "proposal") {
    linkedDocuments =
      documents?.filter((d) =>
        row.instanceIds && row.instanceIds.length > 0
          ? row.instanceIds.includes(d.instanceId ?? "")
          : false
      ) ?? [];
  } else if (row?.category == "policy") {
    linkedDocuments =
      documents?.filter(
        (d) =>
          (d.associatedDocumentId &&
            d.associatedDocumentId == row.instanceId) ||
          (d.instanceIds &&
            d.instanceIds.length > 0 &&
            d.instanceIds.includes(row.instanceId ?? ""))
      ) ?? [];
  } else if (row?.category == "endorsement") {
    linkedDocuments =
      documents?.filter(
        (d) => (row.associatedDocumentId && row.associatedDocumentId == d.instanceId)
      ) ?? [];
  }

  if (linkedDocuments.length == 0) return (<>—</>);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        flexWrap: "wrap",
      }}
    >
      <Pill
        $nowrap
        type={linkedDocuments[0].category}
        pillText={
          linkedDocuments[0].name.length > 17
            ? linkedDocuments[0].name.slice(0, 14) + "..."
            : linkedDocuments[0].name
        }
        onClick={(e) => {
          e.stopPropagation();
          onDocumentView(linkedDocuments[0]);
        }}
      />
      {linkedDocuments.length > 1 && !show && (
        <Pill
          $nowrap
          type="policy"
          pillText={`+${linkedDocuments.length - 1}`}
          onClick={(e) => {
            e.stopPropagation();
            setShow(true);
          }}
        />
      )}
      {linkedDocuments.length > 1 && show && linkedDocuments.slice(1).map(d => (
        <Pill
          $nowrap
          type={d.category}
          pillText={
            d.name.length > 17
              ? d.name.slice(0, 14) + "..."
              : d.name
          }
          onClick={(e) => {
            e.stopPropagation();
            onDocumentView(d);
          }}
        />
      ))}
    </div>
  );
};

const getInitialSelected = (documents) =>
  documents.reduce((acc, _, index) => {
    acc[index] = false;
    return acc;
  }, {});

const DocumentTable: React.FC<DocumentTableProps> = ({
  docList,
  reports,
  documents,
  selectedDocuments,
  setSelectedDocuments,
  retrieveDocuments,
  onDocumentView,
  isLoading,
  cookie,
  handleFileUpload,
  filtered,
}) => {
  const [selected, setSelected] = useState<Record<string, boolean>>(
    getInitialSelected(documents)
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDeletingToast, setShowDeletingToast] = useState<boolean>(false);
  const [downloadInitiated, setDownloadInitiated] = useState<boolean>(false);
  const [showDownloadToast, setShowDownloadToast] = useState<boolean>(false);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | undefined>();
  const [sortedDocuments, setSortedDocuments] = useState<DocumentType[]>([]);
  const [sort, setSort] = useState<string>("");

    useEffect(() => {
      if (showDeletingToast) {
        const timer = setTimeout(() => {
          setShowDeletingToast(false);
        }, 5000); // 5 seconds
    
        return () => clearTimeout(timer);
      } 
      
      if (showDownloadToast) {
        const timer = setTimeout(() => {
          setShowDownloadToast(false);
        }, 5000); // 5 seconds
    
        return () => clearTimeout(timer);
      } 
      
    }, [showDownloadToast, showDeletingToast]);
  

  useEffect(() => {
    if (documents && sort !== "") {
      const statusOrder = [
        "analyzed",
        "generated",
        "analyzing",
        "generating",
        "uploaded",
        "uploading",
        "failed",
      ];
      setSortedDocuments(
        [...documents].sort((a, b) => {
          if (sort === "name") {
            return a.name.localeCompare(b.name);
          } else if (sort === "name-reverse") {
            return b.name.localeCompare(a.name);
          } else if (sort === "status") {
            return (
              statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
            );
          } else if (sort === "status-reverse") {
            return (
              statusOrder.indexOf(b.status) - statusOrder.indexOf(a.status)
            );
          } else if (sort === "created at") {
            const dateA = a.createdAt
              ? new Date(a.createdAt).getTime()
              : new Date(0).getTime(); // Use epoch date if undefined
            const dateB = b.createdAt
              ? new Date(b.createdAt).getTime()
              : new Date(0).getTime(); // Use epoch date if undefined
            return dateB - dateA;
          } else if (sort === "created at-reverse") {
            const dateA = a.createdAt
              ? new Date(a.createdAt).getTime()
              : new Date(0).getTime(); // Use epoch date if undefined
            const dateB = b.createdAt
              ? new Date(b.createdAt).getTime()
              : new Date(0).getTime(); // Use epoch date if undefined
            return dateA - dateB;
          } else if (sort === "verified") {
            return (
              (a.status == "analyzed" ? a.percentVerified ?? 0 : -1) -
              (b.status == "analyzed" ? b.percentVerified ?? 0 : -1)
            );
          } else if (sort === "verified-reverse") {
            return (
              (b.status == "analyzed" ? b.percentVerified ?? 0 : -1) -
              (a.status == "analyzed" ? a.percentVerified ?? 0 : -1)
            );
          }
          return 0;
        })
      );
    } else if (documents && sort === "") {
      setSortedDocuments(documents);
    }
  }, [documents, sort]);

  const setNewSort = (newSort: string) => {
    if (sort == newSort) setSort(newSort + "-reverse");
    else if (sort == newSort + "-reverse") setSort("");
    else setSort(newSort);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    Object.keys(files).forEach(async (key) => {
      await handleFileUpload(files[key], "policy", undefined);
    });
  };

  const handleSelectAll = (newValue: boolean) => {
    setSelected((prevSelected) => {
      const updatedSelected = { ...prevSelected };
      for (const key in prevSelected) {
        updatedSelected[key] = newValue;
      }
      return updatedSelected;
    });
  };

  const handleCheckboxRow = (instanceId: string) => {
    if (selectedDocuments.includes(instanceId)) {
      setSelectedDocuments(selectedDocuments.filter((s) => s != instanceId));
    } else {
      setSelectedDocuments([...selectedDocuments, instanceId]);
    }
  };

  // TODO Strongly suggest refactoring download route to Document Service. You can then use the useQuery hook to manage fail states easily here see "src/hooks"
  const initiateDownload = async (document: DocumentType) => {
    setDownloadInitiated(true);
    setShowDownloadToast(true);
    await handleDownload(document, cookie, window);
    setDownloadInitiated(false);
  };

  // TODO Strongly suggest refactoring delete route to Document Service. You can then use the useQuery hook to manage fail states easily here see "src/hooks"
  const handleDelete = async (row: DocumentType) => {
    setDeleting(true);
    setShowDeletingToast(true);
    console.log("ABOUT TO DELETE");
    console.log(row);
    await deleteDocument(cookie || "", row.instanceId || "", row?.category == "analysis" || row?.category == "comparison", row?.category == "proposal");
    setDeleting(false);
    retrieveDocuments && retrieveDocuments();
  };

  const handleDropdownChange = (index: number, value: boolean) => {
    value ? setOpenMenuIndex(index) : setOpenMenuIndex(undefined);
  };

  const renderTableRow = (row: DocumentType, index: number) => {
    const onRowClick = () => {
      onDocumentView(row);
    };
    const dropdownOptions = [
      { label: "Open", onClick: onRowClick, icon: FileInput },
      {
        label: "Download",
        onClick: () => initiateDownload(row),
        icon: Download,
      },
      {
        label: "Delete",
        onClick: () => handleDelete(row),
        icon: Trash,
      },
    ];

    // check if report run succeeded
    let rowStatus: PillType = row.websiteReady ? PillType.GENERATED : row.status as unknown as PillType;
    if (row.category == "policy") {
      const reportRun = reports?.find(d => d.category == "analysis" && d.instanceIds && d.instanceIds.length == 1 && d.instanceIds[0] == row.instanceId);
      if (reportRun?.status == "failed") rowStatus = PillType.FAILED;
    }

    return (
      <BodyTr key={row.instanceId} onClick={onRowClick}>
        <Td>
          <CheckboxInput
            key={JSON.stringify(selected)}
            value={selectedDocuments.includes(row.instanceId ?? "")}
            callback={() => handleCheckboxRow(row.instanceId ?? "")}
          />
        </Td>
        <NameTd>
          <DocumentDescription
            title={row.name}
            type={row.category as unknown as DocType}
            fileSize={"~200kb"} // TODO this is not on the API yet
          />
        </NameTd>
        <Td>
          <Pill type={row.category as unknown as PillType} />
        </Td>
        <Td>{row.createdAt && new Date(row.createdAt).toLocaleDateString()}</Td>
        <StatusTd>
          <Pill type={rowStatus} />
        </StatusTd>
        {/*<VerifiedTd>
          {row.status == "analyzed" && (
            <CircularProgressWithLabel value={row.percentVerified ?? 0} />
          )}
        </VerifiedTd>*/}
        <LinkedTd>
          <LinkedContainer>
            <LinkedDocuments row={row} documents={docList} onDocumentView={onDocumentView} />
          </LinkedContainer>
        </LinkedTd>
        <Td>
          <Dropdown
            icon={Ellipsis}
            options={dropdownOptions}
            value={openMenuIndex === index}
            openStateCallback={(val: boolean) =>
              handleDropdownChange(index, val)
            }
            $alignRight
            key={JSON.stringify(`${index}_${openMenuIndex}`)}
          />
        </Td>
      </BodyTr>
    );
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadAction: UploadActions
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      Object.keys(files).forEach(async (key) => {
        await handleFileUpload(files[key], "policy", undefined);
      });
    }
  };

  return (
    <>
      {isDragging && (
        <DragInfoContainer>
          <DragInfoCard isDragging={isDragging}>
            <Icon src={File2} alt="dropzone icon" />
            <DragInfoTextWrapper>
              <DragInfoHeader>Drop Files to upload to Coverflow</DragInfoHeader>
              <DragInfoSubHeader>Max File Size 10mb</DragInfoSubHeader>
            </DragInfoTextWrapper>
          </DragInfoCard>
        </DragInfoContainer>
      )}
      {showDeletingToast && (
        <Toast>
          {deleting && (
            <>
              <LoadingSpinner />
              <ToastText>Deleting Document</ToastText>
            </>
          )}
          {!deleting && (
            <>
              <ToastText>Document deleted successfully</ToastText>
              <Button
                text="Dismiss"
                handleClick={() => setShowDeletingToast(false)}
              />
            </>
          )}
        </Toast>
      )}
      {showDownloadToast && (
        <Toast>
          {downloadInitiated && (
            <>
              <LoadingSpinner />
              <ToastText>Downloading Document</ToastText>
            </>
          )}
          {!downloadInitiated && (
            <>
              <ToastText>Document downloaded successfully</ToastText>
              <Button
                text="Dismiss"
                handleClick={() => setShowDownloadToast(false)}
              />
            </>
          )}
        </Toast>
      )}

      <TableContainer
        id="dashboardtour-step2"
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isLoading && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
        {documents?.length === 0 && !isLoading && filtered && (
          <SpinnerWrapper>
            <SubHeader>No Results Found</SubHeader>
          </SpinnerWrapper>
        )}
        <div id="dashboardtour-step3">
          {documents?.length === 0 && !isLoading && !filtered && (
            <InitialWrapper>
              <Header>To get started, add your first policy below:</Header>
              <Button
                iconLeft={Upload}
                text="Upload"
                handleClick={handleUpload}
                $large
              />
              <HiddenInput
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, UploadActions.UPLOAD)}
              />
            </InitialWrapper>
          )}
        </div>
        {documents?.length !== 0 && !isLoading && (
          <Table>
            <THead>
              <Tr>
                <div></div>
                <ThDocDescription onClick={() => setNewSort("name")}>
                  Files
                  {(sort == "name" || sort == "name-reverse") && (
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "10px",
                        transform: `translateY(2px) rotate(${
                          sort === "name" ? "90deg" : "270deg"
                        })`,
                      }}
                      src={process.env.PUBLIC_URL + `/assets/thick-arrow.png`}
                    />
                  )}
                </ThDocDescription>
                <ThType>Type</ThType>
                <Th onClick={() => setNewSort("created at")}>
                  Date
                  {(sort == "created at" || sort == "created at-reverse") && (
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "10px",
                        transform: `translateY(2px) rotate(${
                          sort === "created at" ? "90deg" : "270deg"
                        })`,
                      }}
                      src={process.env.PUBLIC_URL + `/assets/thick-arrow.png`}
                    />
                  )}
                </Th>
                <Th onClick={() => setNewSort("status")}>
                  Status
                  {(sort == "status" || sort == "status-reverse") && (
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "10px",
                        transform: `translateY(2px) rotate(${
                          sort === "status" ? "90deg" : "270deg"
                        })`,
                      }}
                      src={process.env.PUBLIC_URL + `/assets/thick-arrow.png`}
                    />
                  )}
                </Th>
                {/*<Th onClick={() => setNewSort("verified")}>
                  Verified
                  {(sort == "verified" || sort == "verified-reverse") && (
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "10px",
                        transform: `translateY(2px) rotate(${
                          sort === "verified" ? "90deg" : "270deg"
                        })`,
                      }}
                      src={process.env.PUBLIC_URL + `/assets/thick-arrow.png`}
                    />
                  )}
                </Th>*/}
                <Th>Linked Docs</Th>
              </Tr>
            </THead>
            <TBody isDragging={isDragging}>
              {sortedDocuments &&
                sortedDocuments.map((doc, index) => renderTableRow(doc, index))}
            </TBody>
          </Table>
        )}
        { (documents && documents.length > 0) && <Space px={150} /> }
      </TableContainer>
    </>
  );
};

export default DocumentTable;
