import { DocumentType } from "../Document";
import { Header } from "../DocumentWizardTable/types";

export interface PolicyComparisonWizardProps {
    handleCancel: () => void;
    handleFileUpload: (file: any, category: 'policy' | 'endorsement', associatedDocumentId?: string) => Promise<any>;
    rows: DocumentType[];
    onSubmit: (initial: DocumentType[]) => Promise<void>;
    alreadySelected?: string[];
    document?: DocumentType | null;
    lastUpload?: string;
}

export enum Pages {
    SELECT_FIRST_POLICY = "Select First Policy",
    SELECT_SECOND_POLICY = "Select Second Policy",
    SELECT_LINES = "Select Lines",
}

export interface SelectFirstPolicyProps {
    rows: DocumentType[];
    headers: Header[];
    handleSelect: (rows: DocumentType[]) => void;
    handleFileUpload: (file: any, category: 'policy' | 'endorsement', associatedDocumentId?: string) => Promise<any>;
    handleContinue: () => void;
    handleCancel: () => void;
    progressBlocked?: boolean;
    selected: DocumentType[];
    handleRemoveFromSelected: (row: DocumentType) => void;
    lastUpload?: string;
}

export interface SelectSecondPolicyProps extends SelectFirstPolicyProps {
    selectedSecondary: DocumentType[];
    handleRemoveFromSelectedSecondary: (row: DocumentType) => void;
}

export enum Lines {
    CYBER = "Cyber",
    D_O = "D&O",
    PROF_LIABILITY = "Professional Liability",
    COMMERCIAL_PROP = "Commercial Property",
    COMMERCIAL_AUTO = "Commercial Auto",
    GENERAL_LIABILITY = "General Liability",
    LIQUOR_LIABILITY = "Liquor Liability",
    WORKERS_COMP = "Workers Comp",
    BOP = "BOP",
    GARAGE = "Garage",
    EPL = "EPL",
    CRIME = "Crime",
    POLLUTION_LIABILITY = "Pollution Liability",
    LOGGING = "Logging",
    BUILDERS_RISK = "Builder's Risk",
    INLAND_MARINE = "Inland Marine",
    UMBRELLA = "Umbrella",
    FARM = "Farm",
}

export interface SelectComparisonProps {
    onComplete: (s: string[]) => void;
    policyName?: string;
    lastUploaded?: DocumentType;
    singlePolicy?: boolean;
    proposal?: boolean;
    loading?: boolean;
}