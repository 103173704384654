// TODO Confirm accurate color values

export default {
  PILL_TYPE_COLORS: {
    DEFAULT: {
      TEXT: "#18181C",
      BACKGROUND: "white",
      BORDER: "#E3E4E9",
    },
    // COMPARISON: {
    //     TEXT: "#206AC2",
    //     BACKGROUND: "#E9F0F9",
    //     BORDER: "#206AC240",
    // },
    // POLICY: {
    //     TEXT: "#A746F3",
    //     BACKGROUND: "#F6ECFE",
    //     BORDER: "#E2C2FB",
    // },
    // PROPOSAL: {
    //     TEXT: "#008293",
    //     BACKGROUND: "#E5F2F4",
    //     BORDER: "#ACD6DC",
    // },
    ANALYZED: {
      TEXT: "#038750",
      BACKGROUND: "#E6F3EE",
      BORDER: "#97CFB7",
    },
    GENERATED: {
      TEXT: "#038750",
      BACKGROUND: "#E6F3EE",
      BORDER: "#97CFB7",
    },
    ANALYZING: {
      TEXT: "#CE7205",
      BACKGROUND: "#FAF1E6",
      BORDER: "#EBC597",
    },
    ANALYZING_POLICY: {
      TEXT: "#CE7205",
      BACKGROUND: "#FAF1E6",
      BORDER: "#EBC597",
    },
    FAILED: {
      TEXT: "#FF6464",
      BACKGROUND: "#FFD8D8",
      BORDER: "#FF6464",
    },
  },
  UI: {
    BORDER: {
      STANDARD: "#DFE1E6",
      SELECTED: "#4455F3",
      ACCENT: "#495AF3",
      DANGER: "#EA9999",
      HIGHLIGHT: "#808CF7",
    },
    BACKGROUND: {
      STANDARD: "#FFFFFF",
      ACTIVE: "#ECEEFE",
      SELECTED: "#F6F6FE",
      SELECTED_DARK: "#dfdffb",
      ELEMENT: "#FCFCFD",
      MODAL: "#18181B",
      ACCENT: "#FBFCFF",
      DANGER: "#FAE7E7",
      DANGER_DARK: "#f6d3d3",
      SUCCESS: "#E1FDE6",
      WARNING: "#FDF6E1",
    },
    TEXT: {
      STANDARD: "#3F3F46",
      SECONDARY: "#FFFFFF",
      EXTRA_LIGHT: "#AAAAAA",
      LIGHT: "#71717A",
      GREY: "#79797E",
      DARK_GREY: "#18181B",
      ACTIVE: "#4456F3",
      DANGER: "#CA0607",
      SUCCESS: "#038B52",
      WARNING: "#EB8A14",
    },
    BUTTON: {
      PRIMARY: {
        BACKGROUND: "#FFFFFF",
        TEXT: "#3F3F46",
        HOVER: "#DFE1E6",
      },
      SECONDARY: {
        BACKGROUND: "#4455F3",
        BORDER: "#808CF7",
        TEXT: "#FFFFFF",
        HOVER: "#DFE1E6",
      },
      DANGER: {
        BACKGROUND: "#D32F2F",
        TEXT: "#FFFFFF",
        HOVER: "#AE3434",
      },
    },
  },
};
