import styled from "styled-components";
import { ColoredTextStyleProps } from "./types";

export const ColoredTextWrapper = styled.div<ColoredTextStyleProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: min-content;
  border-radius: 4px;
  padding: 2px 8px;
  color: ${({ color }) => color};
  white-space: nowrap;

  & > * {
    color: ${({ color }) => color};
    white-space: nowrap;
  }
`;
