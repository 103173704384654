import styled from "styled-components";
import SIZING from '../../designSystem/sizing'
import { StyledText } from "../Text/styles";
import COLORS from "../../designSystem/colors";
import { MenuStyleProps } from "./types";

export const MenuContainer = styled.div<MenuStyleProps>`
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    background-color: ${COLORS.UI.BACKGROUND.STANDARD};
    box-shadow: 0px 4px 6px -1px #0000001A;
    width: ${({ $grow }) => ($grow ? "100%" : "min-content")};
    white-space: nowrap;
    position: absolute;
    margin-top: ${SIZING.SPACING.SMALL};
    ${({ $alignRight }) => ($alignRight ? "right: 0px" : "")};
    z-index: 100;
`;

export const MenuOption = styled.div`
    display: flex;
    vertical-align: center;
    cursor: pointer;
    padding: ${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD};
    &:hover {
        background-color: ${COLORS.UI.BUTTON.PRIMARY.HOVER}
    }
    :first-child {
        margin-right: ${SIZING.SPACING.STANDARD};
    }
`;

export const MenuOptionLabel = styled(StyledText)`
    flex-grow: 1;
`