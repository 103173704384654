export const analysisWT = [
    {
      element: '#analysistour-step1',
      title: 'Congratulations!',
      intro: 'You\'ve completed your first analysis. Let\'s show you some of the cool features!',
    },
    {
      element: '#analysistour-step3',
      title: 'Original Policy',
      intro: 'As you can tell, here\'s your original policy.',
    },
    {
      element: '#analysistour-step4',
      title: "AI Breakdown",
      intro: "And our AI breakdown is here on the right.",
    },
    {
      element: '#analysistour-step5',
      title: "Data Point",
      intro: "This is a sample data point. When you click it, you'll notice something cool happen on the left!",
    },
    {
      element: '#analysistour-step6',
      title: "Line Filtering",
      intro: "Feel free to filter results by coverage line here.",
    },
    {
      element: '#analysistour-step7',
      title: "PDF Download",
      intro: "And you can even download a PDF of the analysis here.",
    },
    {
      element: '#analysistour-step8',
      title: "Final",
      intro: "That's it for now! Go ahead and try running a comparison to see more.",
    },
];