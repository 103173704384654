import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { CountProps, FilterProps } from "./types";
import { getColorFromCount } from "./utils";

export const ComparisonFilterContainer = styled.div`
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  width: 100%;

  & > div {
    border-bottom: 1px solid ${colors.UI.BORDER.STANDARD};
  }

  & > :first-child {
    border-radius: ${sizing.RADIUS.MEDIUM} ${sizing.RADIUS.MEDIUM} 0 0;
  }
  & > :last-child {
    border: none;
    border-radius: 0 0 ${sizing.RADIUS.MEDIUM} ${sizing.RADIUS.MEDIUM};
  }
  position: sticky;
  top: ${sizing.SPACING.STANDARD};
  min-width: 300px;
`;

export const LineFilterContainer = styled.div<FilterProps>`
  padding: ${sizing.SPACING.STANDARD};
  display: flex;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? colors.UI.BACKGROUND.SELECTED : colors.UI.BACKGROUND.STANDARD};
  cursor: pointer;

  &:hover {
    background-color: ${colors.UI.BACKGROUND.SELECTED_DARK};
  }
`;

export const Text = styled.p`
  flex-grow: 1;
  margin: 0 ${sizing.SPACING.STANDARD} 0 0;
  white-space: nowrap;
`;
