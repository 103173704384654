import styled from "styled-components";
import COLORS from "../../designSystem/colors";
import SIZING from "../../designSystem/sizing";

export const TopBarContainer = styled.div`
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    padding: ${SIZING.SPACING.LARGE};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    display: flex;
    margin: 0 ${SIZING.SPACING.STANDARD};
`;

export const FilterWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
`;

export const ProposalWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > * {
        margin-left: ${SIZING.SPACING.SMALL};
    }
`;

export const LinesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 768px;
    flex-grow: 3;
`;