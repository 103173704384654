import React, { ReactNode } from 'react';
import Button from '../Button';
import Text from '../Text';
import { PaginationWrapper, PaginationButtonWrapper } from './styles';
import ChevronLeft from '../../assets/icons/ChevronLeft.svg';
import ChevronRight from '../../assets/icons/ChevronRight.svg';
import Ellipsis from '../../assets/icons/Ellipsis.svg';
import { PaginationControlsProps } from './types';

const PaginationControls: React.FC<PaginationControlsProps> = ({ totalPages, handlePageChange, currentPage, arrayLength, itemsPerPage }) => {


    const renderPageButtons = () => {
        const buttons: ReactNode[] = [];
    
        if (totalPages <= 3) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                    <Button
                        key={i}
                        text={`${i}`}
                        handleClick={() => handlePageChange(i)}
                        $borderless={currentPage !== i}
                    />
                );
            }
            return buttons;
        }
    
        if (currentPage > 2) {
            buttons.push(
                <Button
                    key="start-ellipsis"
                    iconLeft={Ellipsis}
                    handleClick={() => handlePageChange(currentPage - 1)}
                    $borderless
                />
            );
        }

        const startPage = Math.max(1, currentPage - 1);
        const endPage = Math.min(totalPages, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <Button
                    key={i}
                    text={`${i}`}
                    handleClick={() => handlePageChange(i)}
                    $borderless={currentPage !== i}
                />
            );
        }

        if (currentPage < totalPages - 1) {
            buttons.push(
                <Button
                    key="end-ellipsis"
                    iconLeft={Ellipsis}
                    handleClick={() => handlePageChange(currentPage + 1)}
                    $borderless
                />
            );
        }
    
        return buttons;
    };


    return (
        <PaginationWrapper>
        { 
            arrayLength === 0 ? 
            <Text content={`No policies`} /> :
            <Text content={`Showing ${1 + (currentPage -1) * itemsPerPage} to ${arrayLength < itemsPerPage * currentPage ? arrayLength : itemsPerPage * currentPage} of ${arrayLength} results`} />
        }
            <PaginationButtonWrapper>
                <Button $borderless iconLeft={ChevronLeft} text="Previous" handleClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {renderPageButtons()}
                <Button $borderless iconRight={ChevronRight}text="Next" handleClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            </PaginationButtonWrapper>
        </PaginationWrapper>
    );
}

export default PaginationControls;