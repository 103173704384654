import React from 'react';
import { ToastProps } from './types';
import { ToastContainer, ToastCard } from './styles';
import { Icon } from '../Icon/styles';

const Toast: React.FC<ToastProps> = ({ children }) => {
    return(<ToastContainer>
        <ToastCard>
            { children }
        </ToastCard>
    </ToastContainer>
)}

export default Toast;


