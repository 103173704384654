import styled from "styled-components";
import SIZING from "../../designSystem/sizing";

export const DocumentDescriptionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: min-content;
    align-items: center;
`

export const Title = styled.p`
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    padding-top: ${SIZING.SPACING.SMALLEST}
`

export const FileSize = styled.p`
    font-weight: light;
    padding-bottom: ${SIZING.SPACING.SMALLEST}
`

export const TextWrapper = styled.div`
    line-height: 1;
    display: flex;
    flex-direction: column;
    > p {
      margin: 0;
    }
`;

export const DocumentIcon = styled.img`
    height: 40px;
    margin-right: ${SIZING.SPACING.SMALL}
`;