import { useEffect, useState } from "react";
import introJs from "intro.js";
import { useNavigate } from "react-router-dom";

import { ComparisonPageProps } from "./types";
import AppHeader from "../../components/Navbar";
import {
  ComparisonDisplay,
  ComparisonPageContainer,
  ComparisonsContainer,
  ComparisonsWrapper,
  FilterComparisonContainer,
  FilterContainer,
  HeadedContent,
  HeadedContentContainer,
  HeadedContentContent,
  HeadedContentContentWrapper,
  HeadedContentHeader,
  HeadedContentHeaderWrapper,
  HeadedContentSubHeader,
} from "./styles";
import TopBar from "../../components/TopBar";
import { Line } from "../../components/TopBar/types";
import ComparisonModal from "../../components/ComparisonModal";
import { DocumentType } from "../../components/Document";
import {
  deleteDocument,
  fetchMatchedPairs,
  markWalkthroughCompleted,
} from "../../requests";
import { Pages } from "../../LandingPage/types";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { comparisonWT } from "../../walkthroughs/comparison";
import ComparisonFilter from "../../components/ComparisonFilter";

const ComparisonPage: React.FC<ComparisonPageProps> = ({
  user,
  clientId,
  setClientId,
  clients,
  setCreateClientModalOpen,
  setSettingsModalOpen,
  onHome,
  handleFileUpload,
  retrieveDocuments,
  onDownload,
  documents,
  setDeleting,
  setShowDeletingToast,
  retrieveUserInfo,
}) => {
  const { comparisonId } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);
  const [selectedLine, setSelectedLine] = useState<Line>("All Lines");
  const [selectedType, setSelectedType] = useState<string>("All Types");
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [childStates, setChildStates] = useState<boolean[]>([]);
  const [localDocument, setLocalDocument] = useState<DocumentType | null>(null);
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [secondDocument, setSecondDocument] = useState<DocumentType | null>(
    null
  );
  const [comparisonNotFound, setComparisonNotFound] = useState<boolean>(false);
  const [comparisonWalkthroughDone, setComparisonWalkthroughDone] =
    useState<boolean>(false);
  const startTour = async () => {
    const intro = introJs();

    intro.setOptions({
      steps: comparisonWT,
      showStepNumbers: true,
      exitOnOverlayClick: false,
    });

    async function onWTComplete() {
      await markWalkthroughCompleted(cookies["user-id"], "comparison");
      setComparisonWalkthroughDone(true);
      retrieveUserInfo();
    }

    intro.oncomplete(() => {
      onWTComplete();
    });

    intro.onexit(() => {
      onWTComplete();
    });

    intro.start();
  };

  useEffect(() => {
    if (localDocument?.instanceIds && localDocument.instanceIds.length > 2) {
      onDownload(localDocument);
      navigate("/");
    }
  }, [localDocument]);

  useEffect(() => {
    const comparisonDocument =
      documents.find((d) => d.instanceId == comparisonId) ?? null;
    console.log("COMPARISON DOC IS");
    console.log(comparisonDocument);
    if (comparisonDocument) {
      console.log(documents);
      setComparisonNotFound(false);
      setLocalDocument(comparisonDocument);
      setDocument(
        documents.find(
          (d) =>
            comparisonDocument.instanceIds &&
            comparisonDocument.instanceIds.length > 0 &&
            d.instanceId == comparisonDocument.instanceIds[0]
        ) ?? null
      );
      setSecondDocument(
        documents.find(
          (d) =>
            comparisonDocument.instanceIds &&
            comparisonDocument.instanceIds.length > 1 &&
            d.instanceId == comparisonDocument.instanceIds[1]
        ) ?? null
      );
    } else if (documents.length > 0) {
      setComparisonNotFound(true);
    }
  }, [comparisonId, documents]);

  const handleCollapseAll = () => {
    setChildStates((prev) => prev.map(() => false));
  };

  const handleExpandAll = () => {
    setChildStates((prev) => prev.map(() => true));
  };

  const handleDelete = async (row: DocumentType | null) => {
    setDeleting(true);
    setShowDeletingToast(true);
    await deleteDocument(
      cookies["user-id"],
      row?.instanceId || "",
      row?.category == "analysis" || row?.category == "comparison",
      row?.category == "proposal"
    );
    setDeleting(false);
    retrieveDocuments && retrieveDocuments();
    onHome();
  };

  //TODO implement
  const regenerateComparison = () => {
    console.log("Regenerate Comparison");
  };

  const handleChildStateChange = (bools: boolean[]) => {
    setChildStates(bools);
  };

  return (
    <ComparisonPageContainer>
      <AppHeader
        clientId={clientId}
        setClientId={setClientId}
        clients={clients}
        setCreateClientModalOpen={setCreateClientModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        onHome={onHome}
        handleFileUpload={handleFileUpload}
        handleRegenerateComparison={regenerateComparison}
        document={localDocument ?? undefined}
        retrieveDocuments={retrieveDocuments}
        page={Pages.COMPARISON}
        handleDelete={handleDelete}
      />
      <TopBar
        document={localDocument ?? undefined}
        onBack={onHome}
        handleCollapseAll={handleCollapseAll}
        handleExpandAll={handleExpandAll}
        download={onDownload}
        deleteDoc={() => handleDelete(localDocument)}
        lines={lineOptions}
        types={["Key Information", "Limits", "Endorsements", "Schedules"]}
        selectedLine={selectedLine}
        setSelectedLine={setSelectedLine}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        page={Pages.COMPARISON}
      />
      {comparisonNotFound && (
        <div
          style={{
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "70vh",
          }}
        >
          404: Comparison Not Found
        </div>
      )}
      {!comparisonNotFound && (
        <ComparisonsContainer>
          <ComparisonsWrapper>
            <ComparisonModal
              modalOpen={true}
              originalDocument={localDocument}
              document={document}
              downloadableDocument={localDocument}
              retrieveDocuments={retrieveDocuments}
              secondDocument={secondDocument}
              childStates={childStates}
              setChildStates={handleChildStateChange}
              setLineOptions={setLineOptions}
              selectedLine={selectedLine}
              selectedType={selectedType}
              onDownload={onDownload}
              user={user}
              startTour={startTour}
              comparisonWalkthroughDone={comparisonWalkthroughDone}
            />
          </ComparisonsWrapper>
        </ComparisonsContainer>
      )}
    </ComparisonPageContainer>
  );
};

export default ComparisonPage;
