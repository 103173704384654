import React, { useEffect, useState } from "react";
import { PillProps, PillType } from "./types";
import {
  IconTextWrapper,
  PeriodsContainer,
  PeriodsDisplay,
  PeriodsWrapper,
  PillContainer,
  Text,
} from "./styles";
import { renderIcon } from "./utils";

const pillTypeToText = (type: string) => {
  if (type == PillType.UPLOADING) return "Uploading";
  else if (type == PillType.UPLOADED) return "Uploaded";
  else if (type == PillType.ANALYZING || type == PillType.ANALYZING_POLICY)
    return "Processing";
  else if (type == PillType.ANALYZED || type == PillType.GENERATED)
    return "Processed";
  else if (type == PillType.FAILED) return "Failed";

  return (type.charAt(0).toUpperCase() + type.slice(1)) as string;
};

const Pill: React.FC<PillProps> = ({
  type,
  pillText,
  onClick,
  $grow,
  $greyscale,
  $nowrap,
}) => {
  const [periods, setPeriods] = useState<string>("");

  const inProgressTypes = [
    PillType.UPLOADING,
    PillType.ANALYZING,
    PillType.ANALYZING_POLICY,
  ];

  useEffect(() => {
    if (inProgressTypes.map((pillType) => `${pillType}`).includes(type)) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) =>
          prevPeriods.length < 3 ? prevPeriods + "." : ""
        );
      }, 500);

      return () => clearInterval(interval);
    } else {
      setPeriods("");
    }
  }, [type]);

  return (
    <PillContainer
      onClick={onClick}
      data-testid="pill"
      type={type}
      $grow={$grow}
      $greyscale={$greyscale}
    >
      <IconTextWrapper>
        {renderIcon(type)}
        <Text type={type} $greyscale={$greyscale} $nowrap={$nowrap}>
          {pillText ? pillText : pillTypeToText(type)}
          {inProgressTypes.map((pillType) => `${pillType}`).includes(type) && (
            <PeriodsContainer>
              <PeriodsWrapper>...</PeriodsWrapper>
              <PeriodsDisplay>{periods}</PeriodsDisplay>
            </PeriodsContainer>
          )}
        </Text>
      </IconTextWrapper>
    </PillContainer>
  );
};

export default Pill;
