import styled from "styled-components";
import COLORS from "../../designSystem/colors";
import SIZING from "../../designSystem/sizing";
import { InputContainerProps, TextInputContainerProps } from "./types";

export const TextInputContainer = styled.div<TextInputContainerProps>`
    border: ${({ $borderless }) =>
      $borderless ? "none;" : `1px solid ${COLORS.UI.BORDER.STANDARD};`} 
    border-radius: ${SIZING.RADIUS.MEDIUM};
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: ${({ $grow }) => ($grow ? "100%" : "min-content")};
    min-width: 0;
    box-sizing: border-box;
    :first-child {
        padding: 0 ${SIZING.SPACING.SMALL} 0 ${SIZING.SPACING.STANDARD};
    }

    /* clears the ‘X’ from Internet Explorer */
    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
    & button {
        pointer-events: ${({ showClear }) => (showClear ? "auto" : "none")};
        opacity: ${({ showClear }) => (showClear ? 1 : 0)};
        transition: opacity 250ms ease;    
        margin: 0;
        height: 20px;
    }
    
`;

export const Input = styled.input<InputContainerProps>`
  ::placeholder {
    color: ${COLORS.UI.TEXT.LIGHT};
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  }

  text-overflow: ellipsis;

  border: none;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};

  &:focus {
    outline: none;
  }

  flex-grow: 1;

  &:disabled {
    background-color: transparent;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
`;
