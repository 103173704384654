import React from "react";
import { NumberedItemProps } from "./types";
import ColoredText from "../ColoredText";
import { getColorFromCount } from "../ComparisonFilter/utils";
import { NumberedItemContainer, NumberedItemLabel } from "./styles";

const NumberedItem: React.FC<NumberedItemProps> = ({ itemLabel, number }) => {
  return (
    <NumberedItemContainer>
        <NumberedItemLabel>{itemLabel}</NumberedItemLabel>
        <ColoredText backgroundColor={getColorFromCount(number, 'background')} color={getColorFromCount(number, 'text')}>{number}</ColoredText>
    </NumberedItemContainer>
  )
};

export default NumberedItem;
