import React, { useEffect, useRef, useState } from 'react';
import { BlurredBackground, OverlayText, ButtonWrapper, CreateProposalContainer, HeaderWrapper, SearchWrapper, SelectedDocumentsWrapper } from './styles';
import { CreateProposalProps } from './types';
import { SubHeader } from '../Header/styles';
import Text from '../Text';
import Button from '../Button';
import Close from '../../assets/icons/x-close.svg'
import CloseActive from '../../assets/icons/Close_Active.svg'
import FileActive from '../../assets/icons/File_active.svg'
import Upload from "../../assets/icons/Upload.svg";
import Search from '../../assets/icons/Search.svg'
import DocumentWizardTable from '../DocumentWizardTable';
import COLORS from '../../designSystem/colors';
import TextInput from '../TextInput';
import { DocumentType } from '../Document';
import { UploadActions } from '../Navbar/types';
import { HiddenInput } from '../Navbar/styles';
import { TableContainer } from '../DocumentTable/styles';
import SelectComparison from '../PolicyComparisonWizard/pages/SelectComparison';
import { analyzePolicy, updateCoverageLines } from '../../requests';
import { useCookies } from 'react-cookie';

const CreateProposal:React.FC<CreateProposalProps> = ({ proposals = [], alreadySelected = [], close, onSubmit, handleFileUpload, agencyId }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
    const [selected, setSelected] = useState<DocumentType[]>([]);
    const [chosen, setChosen] = useState<DocumentType[]>([]);
    const [filteredProposals, setFilteredProposals] = useState<DocumentType[]>(proposals);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [uploadedId, setUploadedId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<string>("createProposal");

    useEffect(() => {
        setChosen(proposals.filter(p => alreadySelected.includes(p.instanceId ?? "")));
    }, [alreadySelected])

    useEffect(() => {
        const notInChosen: DocumentType[] = [];
        selected.forEach(s => {
            if (!chosen.includes(s)) {
                notInChosen.push(s);
            }
        })

        setChosen(prev => [...prev, ...notInChosen])
    }, [selected])

    const handleClose = () => {
        close();
    }

    const handleSearch = (searchTerm) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        const matchingProposals = proposals.filter((proposal) =>
            proposal.name.toLowerCase().includes(lowercasedTerm)
        );
        setFilteredProposals(matchingProposals);
        setSelected([]); // TODO need to investigate how to maintain selected when changing the search field
    }

    const handleCancel = () => {
        close();
    }

    const handleCreateProposal = () => {
        let lineSelection = false;
        for (let i = 0; i < chosen.length; i++) {
            if (chosen[i].status == "uploaded") {
                setPage("selectLines");
                lineSelection = true;
                break;
            }
        }

        if (!lineSelection) onSubmit(chosen);
    }

    const handleSelect = (rows: DocumentType[]) => {
        setSelected(rows)
    }

    const headers = [
        { label: "Files" },
        { label: "Status" }
    ]

    const handleRemoveFromSelected = (docToRemove) => {
        setChosen((previous) => previous.filter((doc) => doc !== docToRemove));
        setSelected((previous) => previous.filter((doc) => doc !== docToRemove));
    }

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
        };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);

        const files = event.dataTransfer.files;
        Object.keys(files).forEach(async (key) => {
            const newId = await handleFileUpload(files[key], "policy", undefined);
            setUploadedId(newId);
        });
    };


    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        uploadAction: UploadActions
    ) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            Object.keys(files).forEach(async (key) => {
                const newId = await handleFileUpload(files[key], "policy", undefined);
                setUploadedId(newId);
            });
        }
    };

    const handleSelectLines = async (selected: string[]) => {
        setLoading(true);

        for (let i = 0; i < chosen.length; i++) {
            await updateCoverageLines(cookies['user-id'], chosen[i].instanceId ?? "", selected);
            await analyzePolicy(cookies['user-id'], "", chosen[i].instanceId ?? "", "internal", true);

        }

        onSubmit(chosen);
        setLoading(false);
    }
    
    useEffect(() => {
        if (uploadedId) {
            const lastUploaded = proposals.find(r => r.instanceId == uploadedId);
            lastUploaded && setChosen(prev => [...prev, lastUploaded])
            setUploadedId(null);
        }
    }, [uploadedId])
    

    return (
        <CreateProposalContainer>
            { agencyId != process.env.REACT_APP_PORTAL_AGENCY_ID && (
                <BlurredBackground>
                    <OverlayText><div style={{ color: "black", fontSize: "30px", fontWeight: "bold" }}>Available with Premium Plan</div></OverlayText>
                </BlurredBackground>
            )}
            { page == "createProposal" && (
                <>
                    <HeaderWrapper>
                        <SubHeader>Create a Proposal</SubHeader>
                        <Button iconLeft={Close} handleClick={handleClose} $borderless/>
                    </HeaderWrapper>
                    <Text content="Choose a couple of policies to generate a proposal" />
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
                        <div style={{ display: "flex", flexDirection: "column"}}>
                            <SearchWrapper>
                                <TextInput icon={Search} $grow placeholder='Search...' onChangeCallback={handleSearch}/>
                            </SearchWrapper>
                            <DocumentWizardTable headers={headers} rows={filteredProposals} onSelect={handleSelect} $width="calc(35vw - 30px)"/>
                        </div>
                        — or —
                        <TableContainer
                            isDragging={isDragging}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            $height="420px"
                            $marginTop="20px"
                        >
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "30px", height: "100%"}}>
                                <div style={{ fontSize: "25px", fontWeight: "600" }}>Upload a Policy:</div>
                                <Button
                                    iconLeft={Upload}
                                    text="Upload"
                                    handleClick={handleUpload}
                                    $large
                                />
                                <HiddenInput
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={(e) => handleFileChange(e, UploadActions.UPLOAD)}
                                />
                            </div>
                        </TableContainer>
                    </div>

                    {/* TODO Bug here because state is set in child and parent independently, need to make child stateless */}
                    {chosen[0] && 
                        <SelectedDocumentsWrapper>
                            {chosen.map((document, index) => {
                                return(
                                <Button
                                    key={index}
                                    handleClick={() => handleRemoveFromSelected(document)}
                                    iconLeft={FileActive}
                                    text={document.name.length < 60 ? document.name : document.name.slice(0,57) + "..."}
                                    iconRight={CloseActive}
                                    $color={COLORS.UI.BORDER.SELECTED}
                                    $background={COLORS.UI.BACKGROUND.SELECTED}
                                />
                            )})}    
                        </SelectedDocumentsWrapper>
                    }

                    <ButtonWrapper>
                        <Button text="Cancel" handleClick={handleCancel}/>
                        <Button disabled={chosen.length === 0} text="Create Proposal" handleClick={handleCreateProposal} $secondary/>
                    </ButtonWrapper>
                </>
            )}

            { page === "selectLines" && 
                <SelectComparison onComplete={handleSelectLines} loading={loading} proposal />
            }
        </CreateProposalContainer>
    );
}

export default CreateProposal;