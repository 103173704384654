import styled, { keyframes } from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ToastContainer = styled.div`
    position: fixed;
    bottom: ${SIZING.SPACING.DOUBLE_LARGE};
    display: flex;
    width: 100%;
    justify-content: space-around;
    left: 0;
    right: 0;
    z-index: 2000;
`;

export const ToastCard = styled.div`
    background-color: ${COLORS.UI.BACKGROUND.STANDARD};
    padding: ${SIZING.SPACING.LARGE};
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    animation: ${slideIn} 0.3s ease-out;
    display: flex;
    align-items: center;

    & > * {
        margin-right: ${SIZING.SPACING.STANDARD}
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

