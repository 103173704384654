import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { ModalBackgroundProps } from "./types";

export const ModalContainer = styled.div<ModalBackgroundProps>`
    position: fixed;
    background: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
    pointer-events: none;

    & > * {
        pointer-events: all;
    }

`;

export const ModalWrapper = styled.div`
    background-color: ${colors.UI.BACKGROUND.STANDARD};
    border: 1px solid ${colors.UI.BACKGROUND.STANDARD};
    border-radius: ${sizing.RADIUS.MEDIUM};
    padding: ${sizing.SPACING.STANDARD};
    max-width: 75vw;
`;

export const ModalBackground = styled.div<ModalBackgroundProps>`
    position: fixed;
    background-color: ${colors.UI.BACKGROUND.MODAL};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    opacity: 50%;
    z-index: 1000;
`