export class ApiClient {
    private baseURL: string;
    private defaultHeaders: HeadersInit;
  
    constructor(baseURL: string, defaultHeaders: HeadersInit = {}) {
      this.baseURL = baseURL;
      this.defaultHeaders = {
        'Content-Type': 'application/json',
        ...defaultHeaders,
      };
    }
  
    private async request<T>(
      endpoint: string,
      method: string,
      body?: unknown,
      customHeaders?: HeadersInit
    ): Promise<T> {
      const headers = { ...this.defaultHeaders, ...customHeaders };
      const options: RequestInit = {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      };
  
      try {
        const response = await fetch(`${this.baseURL}${endpoint}`, options);
  
        if (!response.ok) {
          const errorDetails = await response.text();
          throw new Error(
            `Request failed with status ${response.status}: ${errorDetails}`
          );
        }
  
        return (await response.json()) as T;
      } catch (error) {
        console.error('API Client Error:', error);
        throw error;
      }
    }
  
    public get<T>(endpoint: string, customHeaders?: HeadersInit): Promise<T> {
      return this.request<T>(endpoint, 'GET', undefined, customHeaders);
    }
  
    public post<T>(
      endpoint: string,
      body: unknown,
      customHeaders?: HeadersInit
    ): Promise<T> {
      return this.request<T>(endpoint, 'POST', body, customHeaders);
    }
  
    public put<T>(
      endpoint: string,
      body: unknown,
      customHeaders?: HeadersInit
    ): Promise<T> {
      return this.request<T>(endpoint, 'PUT', body, customHeaders);
    }
  
    public delete<T>(endpoint: string, customHeaders?: HeadersInit): Promise<T> {
      return this.request<T>(endpoint, 'DELETE', undefined, customHeaders);
    }
  }
  
  export default ApiClient;