import React, { useState } from 'react';
import Button from '../Button';
import { ConfirmableButtonProps } from './types';
import colors from '../../designSystem/colors';

const ConfirmableButton: React.FC<ConfirmableButtonProps> = ({ handleClick, iconLeft, iconRight, text }) => {
    const [firstButtonClicked, setFirstButtonClicked] = useState<boolean>(false);


    const handleFirstClick = () => {
        setFirstButtonClicked(true);
    }

    const handleConfirm = (e) => {
        handleClick(e);
        setFirstButtonClicked(false);
    }

    return (
        <>
            {!firstButtonClicked && <Button handleClick={handleFirstClick} iconLeft={iconLeft} text={text} iconRight={iconRight}/>}
            {firstButtonClicked && <Button handleClick={(e) => handleConfirm(e)} text={"confirm?"} $background={colors.UI.BUTTON.DANGER.BACKGROUND} $color={colors.UI.BUTTON.DANGER.TEXT}/> }
        </>
    );
}

export default ConfirmableButton;