import React, { useEffect, useState } from 'react';
import { PolicyComparisonWizardContainer } from './styles';
import { Lines, Pages, PolicyComparisonWizardProps } from './types';
import SelectFirstPolicy from './pages/SelectFirstPolicy';
import SelectSecondPolicy from './pages/SelectSecondPolicy';
import SelectComparison from './pages/SelectComparison';
import { DocumentType } from '../Document';
import { useCookies } from 'react-cookie';
import { analyzePolicy, updateCoverageLines } from '../../requests';

const PolicyComparisonWizard: React.FC<PolicyComparisonWizardProps> = ({ handleCancel, handleFileUpload, rows, onSubmit, document, alreadySelected, lastUpload }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
    const [page, setPage] = useState<Pages>(Pages.SELECT_FIRST_POLICY);

    const [selectedDocuments, setSelectedDocuments] = useState<DocumentType[]>(document ? [document] : []);
    const [chosen, setChosen] = useState<DocumentType[]>(document ? [document] : []);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const filteredRows = rows.filter(p => 
            alreadySelected 
                ? alreadySelected.includes(p.instanceId ?? "") 
                : document?.instanceId == p.instanceId
        );

        setSelectedDocuments(filteredRows);
        setChosen(filteredRows);
    }, [alreadySelected, document])

    useEffect(() => {
        const notInChosen: DocumentType[] = [];
        selectedDocuments.forEach(s => {
            if (!chosen.includes(s)) {
                notInChosen.push(s);
            }
        })

        setChosen(prev => [...prev, ...notInChosen]);
    }, [selectedDocuments])

    // useEffect(() => {
    //     if (chosen.length > 2) {
    //         setChosen(prev => prev.slice(0,2));
    //     }

    // }, [chosen])

    // useEffect(() => {
    //     if (selectedDocuments.length > 2) {
    //         setSelectedDocuments(prev => prev.slice(0,2));
    //     }

    // }, [selectedDocuments])

    useEffect(() => {
        if (alreadySelected && alreadySelected.length > 0) {
            let setInitial = false;
            for (let i = 0; i < alreadySelected.length; i++) {
                const doc = rows.find(d => d.instanceId == alreadySelected[i]);
                if (doc && doc.category == "policy") {
                    setSelectedDocuments([doc]);
                    setInitial = true;
                } 
            }
        }
    }, [alreadySelected])

    const headers = [
        {label: "Files"},
        {label: "Status"},
    ]

    const handleContinue = () => {
        if (chosen.filter(c => c.status == "uploaded").length > 0) {
            setPage(Pages.SELECT_LINES);
        } else {
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        onSubmit(chosen);
    }

    const handleSelectLines = async (selected: string[]) => {
        setLoading(true);

        for (let i = 0; i < chosen.length; i++) {
            if (chosen[i].status == "uploaded") {
                await updateCoverageLines(cookies['user-id'], chosen[i].instanceId ?? "", selected);
                await analyzePolicy(cookies['user-id'], "", chosen[i].instanceId ?? "", "internal", true);
            }
        }

        onSubmit(chosen);
        setLoading(false);
    }

    const handleSelectedInitialDocument = (row: DocumentType[] ) => {
        setSelectedDocuments((prev) => [...prev, ...row]);
    }

    const handleRemoveFromSelected = (docToRemove: DocumentType) => {
        setSelectedDocuments((previous) => previous.filter((doc) => doc !== docToRemove));
        setChosen((previous) => previous.filter((doc) => doc !== docToRemove));
    }

    return(
        <PolicyComparisonWizardContainer>
            {page === Pages.SELECT_FIRST_POLICY && 
                <SelectFirstPolicy 
                    rows={rows}
                    headers={headers}
                    handleSelect={handleSelectedInitialDocument}
                    handleFileUpload={handleFileUpload}
                    handleContinue={handleContinue}
                    progressBlocked={chosen.length < 2}
                    handleCancel={handleCancel}
                    selected={chosen}
                    handleRemoveFromSelected={handleRemoveFromSelected}
                    lastUpload={lastUpload}
                />}
            { page === Pages.SELECT_LINES && 
                <SelectComparison onComplete={handleSelectLines} loading={loading} />
            }
        </PolicyComparisonWizardContainer>
    );
}

export default PolicyComparisonWizard;

