import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";
import { StyledText } from "../Text/styles";
import sizing from "../../designSystem/sizing";

export const PolicyComparisonWizardContainer = styled.div`
    width: 70vw;
    max-height: 95vh;
    overflow-y: scroll;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${SIZING.SPACING.LARGE};
    padding-top: ${SIZING.SPACING.STANDARD};
    
    button:last-child {
        margin-left: ${SIZING.SPACING.STANDARD};
    }
`;

export const ProgressDisplay = styled.div`
    display: flex;
    width: min-content;
    white-space: nowrap;
    margin-bottom: ${SIZING.SPACING.LARGE};
    p {
        color: ${COLORS.UI.TEXT.DARK_GREY};
        font-size: ${SIZING.TEXT.SMALL};
    }

    & > :last-child {
        color: ${COLORS.UI.TEXT.ACTIVE};
    }
`;

export const SelectComparisonButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    justify-content: center;
`;

export const ComparisonButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;

    & button {
        margin-right: ${SIZING.SPACING.SMALL};
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${SIZING.SPACING.SMALL};
`;

export const LightText = styled(StyledText)`
    margin-top: ${SIZING.SPACING.LARGE};
    color: #5D5D5F;
`;

export const SelectedDocumentsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > button {
        margin-top: ${SIZING.SPACING.LARGE};
        margin-right: ${SIZING.SPACING.SMALL}
    }
`;

export const SelectionWrapper = styled.div`
    display: flex;
`;

export const ComparisonWrapper = styled.div`
    display: block;
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    padding: ${SIZING.SPACING.STANDARD};
    margin-top: ${SIZING.SPACING.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
`;

export const SearchWrapper = styled.div`
    margin: ${SIZING.SPACING.LARGE} 0 ${SIZING.SPACING.STANDARD} 0;
`;