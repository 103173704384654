import React, { useState } from "react";
import { ComparisonFilterProps } from "./types";
import { ComparisonFilterContainer, Text, LineFilterContainer } from "./styles";
import { Icon } from "../Icon/styles";
import Check from "../../assets/icons/Check.svg";
import ColoredText from "../ColoredText";
import { getColorFromCount } from "./utils";

const ComparisonFilter: React.FC<ComparisonFilterProps> = ({
  lines,
  handleSelection,
}) => {
  const [selected, setSelected] = useState<number>(0); // index of selected Line

  const handleClick = (index: number) => {
    setSelected(index);
    handleSelection(lines[index].label);
  };

  return (
    <ComparisonFilterContainer>
      {lines.map((line, index) => (
        <LineFilterContainer
          key={index}
          selected={selected === index}
          onClick={() => handleClick(index)}
        >
          <Text>{line.label}</Text>
          {line.count > 0 ? (
            <ColoredText
              backgroundColor={getColorFromCount(line.count, "background")}
              color={getColorFromCount(line.count, "text")}
            >
              {line.count} items
            </ColoredText>
          ) : (
            <Icon
              src={Check}
              alt="checkmark"
              $backgroundColor={getColorFromCount(line.count, "background")}
            />
          )}
        </LineFilterContainer>
      ))}
    </ComparisonFilterContainer>
  );
};

export default ComparisonFilter;
