export const dashboardWT = [
    {
      element: '#dashboardtour-step1',
      title: 'Welcome to Coverflow!',
      intro: 'We\'d love to walk you through some key features of the site.',
    },
    // {
    //   element: '#dashboardtour-step123',
    //   title: 'Welcome Tour',
    // },
    {
      element: '#dashboardtour-step2',
      title: 'Documents List',
      intro: 'To start, this is where all your documents will live—policies, quotes, comparisons, you name it.',
    },
    {
      element: '#dashboardtour-step21',
      title: 'Filter',
      intro: 'You can filter by type of document over here.',
    },
    {
      element: '#dashboardtour-step22',
      title: 'Search',
      intro: 'And search up here.',
    },
    {
      element: '#dashboardtour-step5',
      title: "Compare Policies",
      intro: "Once you've analyzed some policies, you'll be able to compare them here!",
    },
    // {
    //   element: '#dashboardtour-step6',
    //   title: "Create Proposal",
    //   intro: "Similarly, you'll be able to generate a proposal for it here!",
    // },
    {
      element: '#dashboardtour-step7',
      title: "Client List",
      intro: "And your client list lives here. Click to toggle between them, or add a new one!",
    },
    {
      element: '#dashboardtour-step8',
      title: "Final",
      intro: "That's it for now! Go ahead and upload a policy, and our AI will take it away!",
    },
];