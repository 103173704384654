import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";
import { CheckboxContainerProps } from "./types";

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
    width: 20px;
    height: 20px;
    border: 1px solid ${({ $checked }) => ( $checked ? COLORS.UI.BORDER.SELECTED : COLORS.UI.BORDER.STANDARD )};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    background-color: ${({ $checked }) => ( $checked ? COLORS.UI.BACKGROUND.SELECTED : COLORS.UI.BACKGROUND.STANDARD )};
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;
`;
