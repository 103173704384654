import styled from "styled-components";
import { Icon } from "../Icon/styles";
import sizing from "../../designSystem/sizing";

    const Text = styled.p`
        margin: 0;
        padding: 0;
    `;

    export const DocumentActionContainer = styled.div`
        display: block;
        margin-right: ${sizing.SPACING.LARGE}
    `;

    export const Heading = styled(Text)`
        font-weight: 500;
        margin-bottom: ${sizing.SPACING.SMALL};
    `

    export const NameContainer = styled.div`
        display: flex;
    `;

    export const ActionIcon = styled(Icon)`
        margin-right: ${sizing.SPACING.STANDARD};
    `;

    export const DocumentName = styled(Text)`
        font-weight: 400;
    `;

