import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { EditableTextStyleProps } from "./types";

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const EditableTextContainer = styled.div<EditableTextStyleProps>`
  word-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid ${colors.UI.BACKGROUND.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  flex: 1;
  width: ${({ $grow }) => ($grow ? "100%" : "min-content")};
  // overflow-y: scroll;
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : "")}

  & > * {
    font-weight: 500;
  }

  border: ${({ isEditing, isError, $alwaysBorder }) =>
    isEditing
      ? `
    1px solid ${
      isError ? colors.UI.BORDER.DANGER : colors.UI.BORDER.STANDARD
    };  
    `
      : $alwaysBorder
      ? `1px solid ${colors.UI.BORDER.STANDARD};`
      : "none"};
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
`;
