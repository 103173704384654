import React from 'react';
import { DocumentActionContainer, Heading, NameContainer, DocumentName, ActionIcon } from './styles';
import { ToastDocumentActionProps } from './types';
import File2 from '../../assets/icons/File2.svg';

const ToastDocumentAction: React.FC<ToastDocumentActionProps> = ({ heading, name}) => {
    return(
    <DocumentActionContainer>
        <Heading>{heading}</Heading>
        <NameContainer>
            <ActionIcon src={File2} alt="file_icon"/>
            <DocumentName>{name}</DocumentName>
        </NameContainer>
    </DocumentActionContainer>
)}

export default ToastDocumentAction;