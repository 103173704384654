export const termsConditions = `

# COVERFLOW TERMS & CONDITIONS
**Effective Date:** January 7, 2025
Welcome to Coverflow! These Terms and Conditions ("**Agreement**") govern your use of the website located at https://www.coverflowtech.com ("**Website**") and the services provided by TRANSMUTEDOTCOM, Inc. (d/b/a "**Coverflow**") through the Website ("**Services**"). By accessing or using the Website or Services, you (“**Customer**”) agree to be bound by these Terms and Conditions. If you do not agree to these Terms, please refrain from using the Website or Services.

---

## DEFINITIONS
“**Affiliate**” means a legal entity that controls, is controlled by, or is under common control with a party, where “control” is defined as owning more than 50% of the voting shares of such entity.
“**Agreement**” means these Terms & Conditions and any Exhibits.
“**Confidential Information**” means any business or technical information disclosed by one party to the other party, including Customer Data, provided that it is identified as confidential at the time of disclosure or that under the circumstances, a person exercising reasonable business judgment would understand it to be confidential or proprietary.
"**Customer Data**" refers to any data or information submitted by Customer to the Website or Services.
"**Services**" refers to the cloud-based software and platform services provided by Coverflow through the Website.
“**Intellectual Property Rights**” means patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, moral rights, know-how, and any other intellectual property rights recognized in any country or jurisdiction.
"**Usage Data**" means aggregated, anonymized data collected to improve and optimize the functionality and performance of the Website and Services.

---

## ENTERPRISE SERVICES
### Enterprise Services.
Customer may access and use the Enterprise Services solely for Customer’s own business purposes in accordance with the Agreement.

### Cooperation and Assistance.
Customer will cooperate with Coverflow in good faith and provide to Coverflow the information that Coverflow reasonably requests and requires to provide the Enterprise Services.

### Responsibilities.
Customer will keep their user IDs and passwords for the Enterprise Services confidential and will be responsible for all actions taken under their account. Customer will comply with all applicable laws, rules and regulations in connection with its use of the Enterprise Services. Customer will promptly notify Coverflow of any suspected violation of this Agreement and will cooperate with Coverflow to address the suspected violation. Coverflow may suspend Customer’s access to the Enterprise Services in the event that Coverflow reasonably determines that such Customer violated this Agreement. Coverflow agrees to work, in good faith, with Customer to resolve any such issue and restore access as soon as possible.

### Restrictions.
Customer will not allow anyone other than themself to access or use the Enterprise Services from Customer’s accounts. Customer will not: (i) attempt to interfere with or disrupt the Enterprise Services (or any related systems or networks) or use the Enterprise Services other than directly for Customer’s benefit; (ii) copy, modify or distribute any portion of the Enterprise Services; (iii) rent, lease, or resell the Enterprise Services; (iv) transfer any of its rights hereunder; or (v) reverse-engineer or access the Enterprise Services in order to build a competitive product or service.

### Customer Data.
Customer is responsible for obtaining any necessary right and licenses for use of the Customer Data by Customer and Coverflow as contemplated in this Agreement. Customer agrees that it has the legal right and authority to access, use and disclose to Coverflow any Customer Data. Customer authorizes Coverflow to access, process, and use the Customer Data as necessary to perform and fulfill its obligations hereunder. Coverflow will process and maintain Customer Data consistent with the Data Security standard determined in Exhibit A of this agreement.

### Information Security.
Coverflow will use commercially reasonable and industry standard technical and organizational measures designed to prevent unauthorized access, use, alteration or disclosure of the Enterprise Services or Customer Data.

### Usage Data.
Coverflow may collect and analyze data and information relating to Customer’s use and the performance of the Enterprise Services and related systems and technologies therefrom to improve and enhance the Enterprise Services. Unless instructed otherwise by Customer for specific types or classes of data, Coverflow may also collect, use and/or analyze Usage Data from Customer in an anonymized, aggregated manner for purposes of improving and enhancing the quality and nature of the Enterprise Services, or to market or publish general information and statistics, provided that Coverflow does not specifically identify Customer (or disclose data in a manner that Customer could subsequently be identified) or disclose any personally identifiable information or Confidential Information in the course of collecting, using, analyzing, marketing or publishing that data or information. Customer acknowledges that in order for Customer to utilize certain Coverflow product features available in the Enterprise Services, Coverflow shall utilize Usage Data to enable the functioning of these features.
Marketing. Coverflow may use Customer's name and logo in its marketing materials, including but not limited to website, social media, press releases, case studies, and promotional content, for the purpose of identifying Customer as a client of Coverflow. Coverflow may solicit and use testimonials, quotes, or statements from Customer's employees or directors in its marketing materials. Such content may be attributed to the individual and their position within Customer's organization.

---

## PROPRIETARY RIGHTS.
### Customer owns and retains: 

(i) the Customer Data; (ii) Customer’s name, logo and other trademarks; and (iii) all Intellectual Property Rights in and to any of the foregoing.

### Coverflow owns and retains: 
(i) the Enterprise Services, and all improvements, enhancements or modifications made by any party; (ii) the Usage Data; (iii) any software, applications, inventions or other technology developed by Coverflow in connection with providing the Enterprise Services; (iv) Coverflow’s name, logo, and other trademarks; and (v) all Intellectual Property Rights in and to any of the foregoing.

---

## CONFIDENTIALITY
### Use and Nondisclosure.
A receiving party will not use the disclosing party’s Confidential Information except as necessary under this Agreement and will not disclose Confidential Information to any third party except: (i) to those of its employees and contractors who have a business need to know such Confidential Information; provided that each such employee and contractor is bound to confidentiality restrictions at least as restrictive as the terms set forth in this Agreement or (ii) as further described in the Data Processing Addendum. Each receiving party will protect the disclosing party’s Confidential Information from unauthorized use and disclosure using efforts equivalent to the efforts that the receiving party uses with respect to its own confidential information and in no event less than a reasonable standard of care. The provisions of this Section 5(a) will remain in effect during the Term and for a period of five (5) years after the expiration or termination thereof, except with regard to Customer Data and trade secrets of the disclosing party, which will be held in confidence indefinitely as long as such information remains sensitive.

### Reverse Engineering.
User will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels. 

### Exclusions.
The obligations and restrictions set forth in Section 5(a) will not apply to any information that: (i) is or becomes generally known to the public through no fault of or breach of this Agreement by the receiving party; (ii) is rightfully known by the receiving party at the time of disclosure; (iii) is independently developed by the receiving party without access to the disclosing party’s Confidential Information; or (iv) the receiving party rightfully obtains from a third party who has the right to disclose such information without breach of any confidentiality obligation to the disclosing party.

### Permitted Disclosures.
The provisions of this Section 5 will not restrict either party from disclosing the other party’s Confidential Information: (i) pursuant to the order or requirement of a court, administrative agency, or other governmental body; provided that to the extent legally permitted, the party required to make such a disclosure gives reasonable notice to the other party to enable it to contest such order or requirement or limit the scope of such request; (ii) on a confidential basis to its legal or professional financial advisors; (iii) as required under applicable securities regulations.

### Injunctive Relief.
The receiving party acknowledges that disclosure of Confidential Information could cause substantial harm for which damages alone may not be a sufficient remedy, and therefore that upon any such disclosure by the receiving party, the disclosing party will be entitled to seek appropriate equitable relief in addition to whatever other remedies it might have at law.

---

## WARRANTY
### Disclaimer.
COVERFLOW MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, IN CONNECTION WITH THIS AGREEMENT OR THE ENTERPRISE SERVICES AND COVERFLOW HEREBY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. COVERFLOW DISCLAIMS ANY WARRANTY THAT THE ENTERPRISE SERVICES WILL BE ERROR FREE OR UNINTERRUPTED OR THAT ALL ERRORS WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM COVERFLOW OR ELSEWHERE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. Customer assumes sole responsibility and liability for results obtained from the use of the Enterprise Services and for conclusions drawn from such use. Coverflow will have no liability for any claims, losses, or damages caused by errors or omissions in any Customer Data or other information provided to Coverflow by Customer in connection with the Enterprise Services or any actions taken by Coverflow at Customer’s direction. Coverflow will have no liability for any claims, losses or damages arising out of or in connection with Customer’s use of any third-party products, services, software or web sites that Customer may choose to integrate or use with the Enterprise Services.
TERM AND TERMINATION

### Term.
This Agreement will commence on the Effective Date and continue indefinitely (the “Term”), unless terminated earlier as provided in this Agreement.

### Termination for Cause.
Either party may terminate this Agreement upon written notice if the other party breaches any material terms of this Agreement and fails to correct the breach within thirty (30) days following written notice from the non-breaching party specifying the breach.

### Rights and Obligations Upon Expiration or Termination.
Upon expiration or termination of this Agreement, Customer’s right to access and use the Enterprise Services will immediately terminate and each will immediately cease all use of the Enterprise Services. Customer has the right to export all Customer Data, at any time, prior to the effective date of expiration or termination of this Agreement. Upon expiration or termination of this Agreement, Coverflow will delete all Customer Data in accordance with the Section 8(b) of the DPA.

### Survival.
The rights and obligations of Coverflow and Customer contained in Sections 2(g) (Usage Data), 3 (Fees; Expenses; Taxes), 4 (Proprietary Rights), 5 (Confidentiality), 7(c) (Rights and Obligations Upon Expiration or Termination), 7(d) (Survival), 8 (Indemnification), 9 (Limitation of Liability), and 10 (General) will survive any expiration or termination of this Agreement.

---

## INDEMNIFICATION
### Indemnification by Coverflow.
Coverflow will defend Customer, its officers, directors and employees, from and against any suit or action brought by a third-party against Customer: (i) alleging that the Enterprise Services, as provided by Coverflow and when used by Customer pursuant to this Agreement, infringes any Intellectual Property Right of a third party (the “IP Indemnity”); or (ii) resulting from unauthorized disclosure and misuse of Customer Data directly resulting from Coverflow’s breach of its obligations under Section 2(e) (Customer Data) or Section 2(f) (Information Security) (the “Data Indemnity”). Coverflow shall indemnify and hold harmless Customer from and against any damages and costs awarded against Customer or agreed in settlement by Coverflow (including reasonable attorneys’ fees) resulting from such claim, provided that: (x) Customer provides Coverflow with prompt written notice of such claim; (y) Customer provides reasonable cooperation to Coverflow, at Coverflow’s expense, in the defense and settlement of such claim; and (z) Coverflow has sole authority to defend or settle such claim, provided that it may not settle any claim in a manner that imposes any material liability upon Customer or requires Customer to admit wrongdoing.

### Injunctions. 
If Customer’s use of the Enterprise Services is, or in Coverflow’s opinion is likely to be, enjoined due to the type of claim specified in Section 8(a)(i), then Coverflow may at its sole option and expense: (i) replace or modify the Enterprise Services to make them non-infringing and of equivalent functionality; (ii) procure for Customer the right to continue using the Enterprise Services under the terms of this Agreement; or (iii) if Coverflow is unable to accomplish either (i) or (ii) despite using its commercially reasonable efforts, terminate Customer’s rights and Coverflow’s obligation under this Agreement with respect to such Enterprise Services and refund to Customer a pro-rata portion of the Fees paid for the remaining portion of the Term during which Customer would have had access to the Enterprise Services.

### Exclusions. 
Notwithstanding the terms of Section 8(a), Coverflow will have no liability for any claim of any kind to the extent that it arises from: (i) Customer Data (as provided to Coverflow), Third-Party Products, or any other portions or components integrated with the Enterprise Services by Customer and not supplied by Coverflow; (ii) any modification, combination, or development of the Enterprise Services or portions or components thereof that is not performed by Coverflow; (iii) the combination, operation or use of the Enterprise Services with equipment, devices, or software not supplied by Coverflow, if a claim would not have occurred but for such combination, operation or use; or (iv) Customer’s use of the Enterprise Services other than in accordance with this Agreement.

### Sole Remedy.
 THE FOREGOING STATES COVEFLOW’S AND ITS LICENSORS’ SOLE LIABILITY AND CUSTOMER’S SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY ALLEGED OR ACTUAL INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS BY THE ENTERPRISE SERVICES.

### Indemnification by Customer.
Customer will defend Coverflow, its officers, directors and employees, from and against any action or suit brought against Coverflow by a third party (i) alleging the Customer Data infringes or misappropriates any Intellectual Property Rights; (ii) related to ownership or use of Customer Data (as provided to Coverflow); or (iii) related to Customer administrator’s actions with respect to the Customer’s account. Customer will indemnify and hold harmless Coverflow from and against any damages and costs awarded against Coverflow or agreed in settlement by Customer (including reasonable attorneys’ fees) resulting from such claim, provided that (x) Coverflow provides Customer with prompt written notice of such claim; (y) Coverflow provides reasonable cooperation to Customer, at Customer’s expense, in the defense and settlement of such claim; and (z) Customer has sole authority to defend or settle such claim, provided that it may not settle any claim in a manner that imposes any material liability upon Coverflow or requires Coverflow to admit wrongdoing.

---

## LIMITATION OF LIABILITY.
### Exclusion of Damages. 
To the fullest extent permitted by law, except for Excluded Claims (as defined below in Section 9(c) and for which there will be no cap on liability), neither Customer nor Coverflow, and its Affiliates and suppliers, will be liable under this Agreement for (i) indirect, special, incidental, consequential, exemplary, or punitive damages; or (ii) loss of use, data, business, revenues, or profits (in each case whether direct or indirect), even if the party knew or should have known that such damages were possible, even if a remedy fails of its essential purpose, and regardless of the type of action or theory of liability.

### Total Liability. 
To the fullest extent permitted by law, except for Excluded Claims (for which there shall be no cap on liability) or Special Claims (which are subject to the Enhanced Liability Cap set forth in Section 9(d)), neither party’s aggregate liability under this Agreement will exceed the amount paid by Customer to Coverflow during the twelve months prior to the event giving rise to liability.

### Excluded Claims. 
“Excluded Claims” means: (i) any intentional misconduct or gross negligence by either party; (ii) any amounts payable to third parties pursuant to Coverflow’s IP Indemnity obligations under Section 8(a)(i); or (iii) any amounts payable to third parties pursuant to Customer’s indemnification obligations under Section 8(e) (Indemnification by Customer).

### Special Claims. 
“Special Claims” means (i) any breach by Coverflow of Section 2(e) (Customer Data), Section 2(f) (Information Security), or Section 5 (Confidentiality) resulting in unauthorized disclosure or misuse of Customer Data; or (ii) any amounts payable to third parties pursuant to Coverflow’s Data Indemnity obligations under Section 8(a)(ii).

## GENERAL
### Governing Law. 
This Agreement will be governed by the laws of the State of California, without regard to its conflict of law provisions. Any legal action or proceeding relating to this Agreement will be brought exclusively in the state or federal courts located in San Francisco, CA. Coverflow and Customer hereby agree to submit to the jurisdiction of, and agree that venue is proper in those courts in any such legal action or proceeding.

### Waiver. 
The waiver by either party of any default or breach of this Agreement will not constitute a waiver of any other or subsequent default or breach. No waiver of any provision of this Agreement will be effective unless it is in writing and signed by the party granting the waiver.

### Severability. 
In the event any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions of this Agreement will remain in full force and effect.

### Force Majeure. 
Neither party will be liable hereunder by reason of any failure or delay in the performance of its obligations hereunder (except for the payment of money owed) on account of events beyond the reasonable control of such party, which may include without limitation denial-of-service attacks, strikes, shortages, riots, insurrection, fires, flood, storm, explosions, pandemics, acts of God, war, terrorism, governmental action, labor conditions, earthquakes, rolling blackouts, and internet connectivity disruptions.

### Relationship Between the Parties. 
Nothing in this Agreement will be construed to create a partnership, joint venture or agency relationship between the parties.

### Assignment. 
Neither party may assign its rights or obligations under this Agreement without the other party’s prior written consent. Notwithstanding the foregoing, either party may assign its rights and obligations under this Agreement to an Affiliate as part of a reorganization, or to a purchaser of its business entity or substantially all of its assets or business to which rights and obligations pertain without the other party’s consent, provided that: (a) the purchaser is not insolvent or otherwise unable to pay its debts as they become due; (b) the purchaser is not a competitor of the other party; and (c) any assignee is bound hereby. Other than the foregoing, any attempt by either party to transfer its rights or obligations under this Agreement will be void.

### Entire Agreement. 
This Agreement (including any Exhibits hereto) constitutes the complete and exclusive agreement between the parties concerning its subject matter and supersedes all prior or contemporaneous agreements or understandings, written or oral, concerning the subject matter of this Agreement.

### Amendment. 
This Agreement may not be modified or amended except in a writing signed by a duly authorized representative of each party.

### No Third-Party Beneficiaries. 
This Agreement is intended for the sole and exclusive benefit of the signatories and is not intended to benefit any third party. Only the parties to this Agreement may enforce it.

---

# Exhibit A
**COVERFLOW DATA SECURITY AND PRIVACY STANDARDS**


---

## Security Practices
We take the security of customer data very seriously at Coverflow. If you have additional questions regarding security, we are happy to answer them. Please write to security@coverflow.tech, and we will respond as quickly as we can. This page describes the administrative, technical and physical controls applicable to Coverflow.

### Hosting and Architecture
#### Cloud-based (hosted) services
The infrastructure for Coverflow is provided and hosted by Amazon Web Services, Inc. ("AWS"). Information about security provided by AWS is available from the AWS Security website. Information about security and privacy-related audits and certifications received by AWS, is available from the AWS Compliance website.

#### Large Language Model (LLM) Inference
The inference for Coverflow’s LLM capabilities are provided by OpenAI and Anthropic. Coverflow utilizes OpenAI’s Data Processing Agreement, which guarantees that no Customer Data from Coverflow is ever used for training purposes by OpenAI. Specifically, Coverflow has configured its OpenAI account such that no Customer Data is ever stored by OpenAI, except when required legally. Information about OpenAI’s security practices can be found at the OpenAI Enterprise Privacy page.

Coverflow also utilizes Anthropic’s Zero Retention policy, which guarantees that no Customer Data from Coverflow will ever be retained or used by Anthropic, except when required legally. Information about Anthropic’s security practices can be found at the Anthropic Privacy page.

### Storage of Customer Data
Coverflow stores Customer Data in a MongoDB database. Every byte of Customer Data is encrypted at rest (AES-256) and in transit (TLS 1.2+), and every database account is protected by two-factor authentication. All database passwords and API keys are rotated on a weekly basis.

No Customer Data is, or will ever, be accessible through a publicly available endpoint, and no Customer Data is, or will ever, be accessible by a customer aside from the one who uploaded it.

### Confidentiality and Security Controls
#### Confidentiality
Coverflow places strict controls over its employees' access to Customer Data.

Coverflow employees will never access Customer Data without explicit and written approval of the Customer beforehand. Moreover, Coverflow employees will never even request to access customer data unless it is absolutely necessary for the maintenance or implementation of the product. We have additional technical controls and audit policies in place to ensure that any access to the customer account is logged.

All of our employees and contract personnel are bound to our policies regarding confidentiality and we treat these issues as matters of the highest importance within our company.

#### Return and deletion of customer data
Coverflow provides the option for administrators to delete any and all Customer Data stored by Coverflow at any time during a subscription term. Within 24 hours of administrator-initiated deletion, Coverflow hard deletes all Customer Data from currently running production systems. 

Within 30 days post contract termination, the customer may request return of Customer Data stored by Coverflow (to the extent such data has not already been deleted by the customer). After the 30 day interval has passed, Coverflow will hard delete all Customer Data from its production systems.

### Monitoring and Validation
#### Certificates
Coverflow is currently acquiring SOC2 Type I and Type II compliance. Customers may inquire about the status of our SOC2 compliance process by sending an email to security@coverflow.tech.

At a minimum, Coverflow will align with prevailing industry standards such as SOC 2, or any successor or superseding standard.

#### Audits
To verify that our security practices are sound, the Coverflow services undergo weekly security assessments by internal personnel, and regular audits by respected external security firms. In addition to periodic and targeted audits of the Coverflow services, we also employ the use of continuous hybrid automated scanning of our web platform.

#### Insurance
Coverflow is insured for up to $1M for cybersecurity breaches to its systems. Additionally, we will assume 100% of additional liability of such breaches, should it exceed $1M.

#### Personnel
Coverflow conducts background checks on all employees before employment, and employees receive privacy and security training during onboarding as well as on an ongoing basis. All employees are required to read and sign our comprehensive information security policy covering the security, availability, and confidentiality of the Coverflow services.

For any other questions, please feel free to reach out to security@coverflow.tech, and we will get right back to you.

`;
