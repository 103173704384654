import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import Text from '../../Text';
import { ButtonWrapper, ComparisonButtonWrapper, LightText, ProgressDisplay, SelectComparisonButtonWrapper } from '../styles';
import ChevronRight from '../../../assets/icons/ChevronRight.svg';
import { Icon } from '../../Icon/styles';
import { SubHeader } from '../../Header/styles';
import Button from '../../Button';
import { Lines, SelectComparisonProps } from '../types';
import colors from '../../../designSystem/colors';
import { HeaderWrapper } from '../../CreateProposal/styles';
import Close from '../../../assets/icons/x-close.svg'
import Space from '../../base/Space';
import LoadingSpinner from '../../base/Spinner';

const SelectComparison: React.FC<SelectComparisonProps> = ({ onComplete, policyName, singlePolicy, loading, proposal }) => {
    const [selected, setSelected] = useState<string[]>([]);

    const handleClick = (newLine: Lines) => {
        if (selected.includes(newLine)) {
            setSelected(prev => prev.filter(p => p != newLine));
        } else {
            setSelected(prev => [...prev, newLine])
        }
    }

    const options = [
        Lines.CYBER,
        Lines.D_O,
        Lines.PROF_LIABILITY,
        Lines.COMMERCIAL_PROP,
        Lines.COMMERCIAL_AUTO,
        Lines.GENERAL_LIABILITY,
        Lines.LIQUOR_LIABILITY,
        Lines.WORKERS_COMP,
        Lines.BOP,
        Lines.GARAGE,
        Lines.EPL,
        Lines.CRIME,
        Lines.POLLUTION_LIABILITY,
        Lines.LOGGING,
        Lines.BUILDERS_RISK,
        Lines.INLAND_MARINE,
        Lines.UMBRELLA,
        Lines.FARM,
    ];

    return(
        <>
            <HeaderWrapper>
                <ProgressDisplay>
                    { (!singlePolicy && !proposal) && (
                        <>
                            <Text content="SELECT POLICIES" />
                            <Icon src={ChevronRight} alt="Progress Icon" />
                        </>
                    )}
                    { proposal && (
                        <>
                            <Text content="SELECT POLICIES" />
                            <Icon src={ChevronRight} alt="Progress Icon" />
                        </>
                    )}
                    <Text content="LINES" />
                </ProgressDisplay>
            </HeaderWrapper>
            <SubHeader>Which lines would you like to {singlePolicy ? `analyze${policyName ? ` for ${policyName}` : ""}` : (proposal ? "include in your proposal" : "compare")}?</SubHeader>
            <LightText>Select Lines</LightText>
            <SelectComparisonButtonWrapper>
                <Space px={20} />
                <ComparisonButtonWrapper>
                    {options.map((option) => {
                        return (
                            <Button
                                key={option}
                                text={option}
                                handleClick={() => handleClick(option)}
                                $color={selected.includes(option) ? colors.UI.TEXT.ACTIVE : undefined}
                                $large
                            />
                        )
                    })}
                </ComparisonButtonWrapper>
            </SelectComparisonButtonWrapper>
            <ButtonWrapper>
                { loading && <LoadingSpinner /> }
                { !loading && <Button text={ singlePolicy ? "Analyze" : (proposal ? "Create Proposal" : "Compare") } handleClick={() => onComplete(selected)} disabled={selected.length == 0} $secondary/> }
            </ButtonWrapper>
        </>
    );
}

export default SelectComparison;