import styled from 'styled-components';
import COLORS from '../../designSystem/colors';
import SIZING from '../../designSystem/sizing';
import { SelectContainerProps } from './types';
import { Icon } from '../Icon/styles';

export const SelectContainer = styled.div<SelectContainerProps>`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    min-width: ${({$minWidth}) => ($minWidth ? $minWidth : "min-content")};
    width: ${({$grow}) => ($grow ? "100%" : "min-content")};
    align-items: center;
    position: relative;
    background-color: ${({ $isMenuOpen }) => ($isMenuOpen ? "#F7F9FB" : COLORS.UI.BACKGROUND.STANDARD)};
    flex-grow: 1;
    flex-shrink: 1;
`;

export const StyledLabel = styled.div`
    padding: ${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0;
    cursor: pointer;
`;

export const LabelText = styled.span`
    flex-grow: 1;
    font-weight: 500;
    white-space: nowrap;
`;

export const StyledIcon = styled(Icon)`
    margin-left: ${SIZING.SPACING.STANDARD};
`;
    
export const SearchWrapper = styled.div`
    flex-grow: 1;
    margin: 0;
    padding: ${SIZING.SPACING.SMALLEST};
    & :first-child {
        margin: 0;
    }
`;

export const NoneFoundWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 ${SIZING.SPACING.STANDARD};
    margin-top: ${SIZING.SPACING.STANDARD};
`;

export const MenuSearchFail = styled.p`
    margin: 0;
    padding: 0;
    font-weight: 500;
`;