import React, { useEffect, useState } from 'react';
import { DocumentWizardTableProps, RowType } from './types';
import { DocumentWizardTableContainer, HeaderRow, Cell, Row, DocumentDescriptionContainer, StatusContainer, StatusContainerHeader, FixedHeightRowContainer, NoResultsWrapper } from './styles';
import CheckboxInput from '../CheckboxInput';
import DocumentDescription from '../DocumentDescription';
import Pill from '../Pill';
import PaginationControls from '../PaginationControl';
import { DocumentType } from '../Document';
import { SubHeader } from '../Header/styles';
import { PillType } from '../Pill/types';

const DocumentWizardTable: React.FC<DocumentWizardTableProps> = ({ headers, rows = [], onSelect, singleSelect, $width }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    useEffect(() => {
        setCurrentPage(1);
    }, [rows]);

    const paginatedRows = rows.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    
    const totalPages = Math.ceil(rows.length / itemsPerPage);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleSelect = (rowId: string) => {
        if (singleSelect) {
            setSelected([rowId]);
            const element = rows.find((e) => ( e.instanceId === rowId ));
            if (element) onSelect([element]);
            else onSelect([]);

            return;
        }

        const updatedSelected = selected.includes(rowId)
        ? selected.filter((id) => id !== rowId)
        : [...selected, rowId];

        const selectedRows: DocumentType[] = [];
        
        updatedSelected.forEach(selectedId => {
            const element = rows.find((e) => ( e.instanceId === selectedId ));
            if (element) { selectedRows.push(element); }
        })
        setSelected(updatedSelected);
        onSelect(selectedRows); 
    };

    return (
        <DocumentWizardTableContainer $width={$width}>
            <HeaderRow>
                {headers.map((header, index) => (
                header.label === "Status" ?
                    <StatusContainerHeader key={index}>{header.label}</StatusContainerHeader>:
                    <Cell key={index}>{header.label}</Cell>
                ))}
            </HeaderRow>
            <FixedHeightRowContainer>
            {paginatedRows?.length === 0 && <NoResultsWrapper><SubHeader>No Results Found</SubHeader></NoResultsWrapper>}
            {paginatedRows.map((row, rowIndex) => {
                const globalRowId = row.instanceId!;
                return (
                <Row key={row.instanceId} $selected={selected.includes(globalRowId!)}>
                    <DocumentDescriptionContainer>
                        <CheckboxInput key={`${JSON.stringify(selected)}_${currentPage}`}
                            value={selected.includes(globalRowId!)}
                            callback={() => handleSelect(globalRowId!)}
                        />
                        <DocumentDescription type={row.category} title={row.name}/>
                    </DocumentDescriptionContainer>
                    <StatusContainer>
                        <Pill type={row.websiteReady ? PillType.GENERATED : row.status} $greyscale />
                    </StatusContainer>
                </Row>
            )})}
            </FixedHeightRowContainer>
            <PaginationControls totalPages={totalPages} handlePageChange={(i) => handlePageChange(i)} currentPage={currentPage} arrayLength={rows.length} itemsPerPage={itemsPerPage} />
        </DocumentWizardTableContainer>
    );
}

export default DocumentWizardTable