import React from "react";
import { ComparedValuesProps } from "./types";
import {
  ComparedValuesContainer,
  Divider,
  FirstValueContainer,
  SecondValueContainer,
} from "./styles";

const ComparedValues: React.FC<ComparedValuesProps> = ({
  firstValue,
  secondValue,
}) => {
  return (
    <ComparedValuesContainer>
      <FirstValueContainer>{firstValue}</FirstValueContainer>
      <Divider />
      <SecondValueContainer>{secondValue}</SecondValueContainer>
    </ComparedValuesContainer>
  );
};

export default ComparedValues;
