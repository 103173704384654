import db from "./mongodb";

export async function fetchWithAutoRefresh(url, options) {
  return fetch(url, options).then(async response => {
    const respJson = await response.json();
    if (response.status === 401 && respJson["error"] == "token_expired") {  // Token expired
        const refreshToken = localStorage.getItem('refreshToken') ?? "";
        const refreshResponse = await fetch('/refresh', {
            method: 'POST',
            headers: {'auth-refresh': refreshToken}
        });

        if (refreshResponse.status == 200) {
            const refreshResponseJson = await refreshResponse.json();
            localStorage.setItem('authToken', refreshResponseJson["cookie"])
            options.headers['auth-token'] = refreshResponseJson["cookie"];
            const response2 = await fetch(url, options);

            return {
              status: response2.status,
              body: await response2.json() ?? {}
            }
        } else {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/login`
        }
    }
    return {
      status: response.status,
      body: respJson ?? {}
    };
});
}

export async function createUser () {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/create-user`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'origin': 'default'
    })
  })
}

export async function updateUser (cookie: string, data: any) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/update-user`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

export async function retrieveUser (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-user`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function markWalkthroughCompleted (cookie: string, wt_completed: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/mark-walkthrough-completed`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'wt_completed': wt_completed,
    })
  })
}

export async function retrieveExistingDocuments (cookie: string, clientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-existing-documents`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId,
    })
  })
}

export async function retrieveDocumentOptions (cookie: string) {
  return await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-document-options`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function addDocument (cookie: string, documentId: string, property?: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/add-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_id': documentId,
      'property': property
    })
  })

  return {
    status: resp.status,
  }
}

export async function autofillDocument (cookie: string, instanceId: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/autofill-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': instanceId
    })
  })

  return {
    status: resp.status,
  }
}
export async function retrieveEmailAddresses (cookie: string, clientId: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-email-addresses`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId
    })
  })

  return {
    status: resp.status,
    body: await resp.json()
  }
}

export async function addEmailAddress (cookie: string, email: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/add-email-address`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'email': email
    })
  })

  return {
    status: resp.status,
  }
}

export async function retrieveNumbers (cookie: string, clientId: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-numbers`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId
    })
  })

  return {
    status: resp.status,
    body: await resp.json()
  }
}

export async function addNumber (cookie: string, number: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/add-number`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'number': number
    })
  })

  return {
    status: resp.status,  
  }
}

export async function retrieveCalls (cookie: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-calls`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })

  return {
    status: resp.status,
    body: await resp.json()
  }
}

export async function retrieveEmails (cookie: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-emails`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })

  return {
    status: resp.status,
    body: await resp.json()
  }
}

export async function retrieveReportRuns (cookie: string, clientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-report-runs`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId,
    })
  })
}

export async function uploadDocument (cookie: string, name: string, category: string, clientId: string, associatedDocumentId?: string, displayName?: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/upload-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'name': name,
      'category': category,
      'client_id': clientId,
      'associated_document_id': associatedDocumentId,
      'display_name': displayName
    })
  })
}

export async function updateDocument (cookie: string, instanceId: string, status: string, link: string, fileKey: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/update-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': instanceId,
      'status': status,
      's3_location': link,
      'file_key': fileKey
    })
  })
}

export async function fetchNewEmails (cookie: string, clientId: string) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/fetch-new-emails`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId,
    })
  })

  return {
    status: resp.status,
  }
}

export async function retrieveComms (cookie: string, clientId: string, page: number) {
  const resp = await fetch(`${process.env.REACT_APP_BE_URL}/retrieve-communications`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId,
      'page': page
    })
  })

  return {
    status: resp.status,
    body: await resp.json()
  }
}

export async function updateExtractedData (cookie: string, extractedData: string, clientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/update-extracted-data`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'extracted_data': extractedData,
      'client_id': clientId
    })
  });
}

export async function retrieveExtractedData (cookie: string, clientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-extracted-data`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId,
    })
  })
}

export async function sendAIQuery (cookie: string, type: string, query: string, docId?: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/send-ai-query`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'type': type,
      'query': query,
      'document_instance_id': docId
    })
  })
}

export async function retrieveAIQuery (cookie: string, queryId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-ai-query`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'query_id': queryId
    })
  })
}

export async function retrieveAlerts (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-alerts`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function sendAlertEmail (cookie: string, alertId: string, to: string, subject: string, body: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/send-alert-email`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'alert_id': alertId,
      'to': to,
      'subject': subject,
      'body': body
    })
  })
}

export async function createAlertType (cookie: string, sender: string, keywords: string[], alertType: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/create-alert-type`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'sender': sender,
      'keywords': keywords,
      'alert_type': alertType,
    })
  })
}

export async function retrieveAlertTypes (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-alert-types`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function retrieveProperties (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-properties`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function comparePolicyCoverage (cookie: string, document_instance_ids: string[], inMarkdown?: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/compare-policies`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_ids': document_instance_ids,
      'in_markdown': inMarkdown
    })
  })
}

export async function analyzePolicy (cookie: string, statement: string, documentInstanceId: string, reportType: string, inMarkdown?: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/analyze-policy`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': documentInstanceId,
      'statement': statement,
      'report_type': reportType,
      'in_markdown': inMarkdown
    })
  })
}

export async function login (username: string, password: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/login`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'username': username,
      'password': password
    })
  })
}

export async function logoutUser (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/logout`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
    }
  })
}

export async function validateCookie (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/validate-cookie`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    }
  })
}

export async function createClient (cookie: string, name: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/create-client`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'name': name,
    })
  })
}

export async function retrieveClients (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-clients`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    }
  })
}

export async function validateAccessCode (accessCode: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/validate-access-code`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'access_code': accessCode,
    })
  })
}

export async function createAccount (accessCode: string, username: string, email: string, password: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/create-account`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'access_code': accessCode,
      'username': username,
      'email': email,
      'password': password
    })
  })
}

export async function retrieveDefinitions (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-definitions`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function addDefinition (cookie: string, name: string, definition: string, coverage_line: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/add-definition`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'name': name,
      'definition': definition,
      'coverage_line': coverage_line,
    })
  })
}


export async function editDefinition (cookie: string, definition_id: string, name: string, definition: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/edit-definition`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'definition_id': definition_id,
      'name': name,
      'definition': definition,
    })
  })
}

export async function deleteDefinition (cookie: string, definition_id: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/delete-definition`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'definition_id': definition_id,
    })
  })
}

export async function getPresignedUrl (cookie: string, link: string, document_instance_id: string, is_report: boolean, is_proposal?: boolean) {
  var isExcel = link?.endsWith('.xlsx');
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/get-presigned-url`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': document_instance_id,
      'is_report': is_report,
      'is_proposal': is_proposal,
      'is_excel': isExcel
    })
  })
}

export async function getDocumentResults (cookie: string, document_instance_id: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/get-document-entries`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': document_instance_id,
    })
  })
}

export async function deleteDocument (cookie: string, document_instance_id: string, is_report: boolean, is_proposal: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/delete-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': document_instance_id,
      'is_report': is_report,
      'is_proposal': is_proposal
    })
  })
}

export async function changePassword (cookie: string, old_password: string, new_password: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/change-password`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'old_password': old_password,
      'new_password': new_password
    })
  })
}

export async function retrieveCredits (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-credits`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function retryReportRun (cookie: string, run_id: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retry-report-run`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'run_id': run_id,
    })
  })
}

export async function updateCoverageLines (cookie: string, instanceId: string, lines: string[]) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/update-document`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': instanceId,
      'coverage_lines': lines,
    })
  })
}

export async function setDisplayName (cookie: string, instanceId: string, displayName: string, isReport: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/set-display-name`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': instanceId,
      'display_name': displayName,
      'is_report': isReport
    })
  })
}

export async function s3Presign (cookie: string, fileName: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/s3-presign`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'file_name': fileName,
    })
  })
}

export async function addCoverageEntry (cookie: string, coverageName: string, coverageLine: string, coverageType: string, keys: any, documentInstanceId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/add-coverage-entry`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'coverage_line': coverageLine,
      'coverage_name': coverageName,
      'coverage_type': coverageType,
      'keys': keys,
      'document_instance_id': documentInstanceId
    })
  })
}

export async function editCoverageEntry (cookie: string, coverageId: string, keys: any, coverageName: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/edit-coverage-entry`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'coverage_id': coverageId,
      'coverage_name': coverageName,
      'keys': keys
    })
  })
}

export async function deleteCoverageEntry (cookie: string, coverageId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/delete-coverage-entry`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'coverage_id': coverageId,
    })
  })
}


export async function generateEditedReport (cookie: string, document_instance_id: string, in_markdown: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/generate-edited-report`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': document_instance_id,
      'in_markdown': in_markdown
    })
  })
}

export async function fetchMatchedPairs (cookie: string, document_instance_ids: string[]) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/fetch-matched-pairs`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'instance_ids': document_instance_ids,
    })
  })
}

export async function editMatchedPair (cookie: string, matched_pair_id: string, similar: boolean) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/edit-matched-pair`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'matched_pair_id': matched_pair_id,
      'similar': similar
    })
  })
}

export async function deleteMatchedPair (cookie: string, matchedPairId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/delete-matched-pair`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'matched_pair_id': matchedPairId,
    })
  })
}

export async function analysisFeedback (cookie: string, documentInstanceId: string, positive: boolean, details?: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/analysis-feedback`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': documentInstanceId,
      'positive': positive,
      'details': details
    })
  })
}

export async function retrieveProposals (cookie: string, clientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-proposals`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'client_id': clientId
    })
  })
}

export async function generateProposal (cookie: string, documentInstanceIds: string[]) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/generate-proposal`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_ids': documentInstanceIds,
    })
  })
}

export async function retrieveAgency (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-agency`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function reassignClient (cookie: string, documentInstanceId: string, newClientId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/reassign-client`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'document_instance_id': documentInstanceId,
      'new_client_id': newClientId
    })
  })
}

export async function retrieveDownIndicators (cookie: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/retrieve-down-indicators`, {
    method: "GET",
    headers: {
      'auth-token': cookie,
    },
  })
}

export async function requestResetPassword (email: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/request-reset-password`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'email': email,
    })
  })
}

export async function validateResetPasswordCode (resetId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/validate-reset-password-code`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'reset_id': resetId,
    })
  })
}

export async function resetPassword (resetId: string, newPassword: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/reset-password`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'reset_id': resetId,
      'new_password': newPassword
    })
  })
}

export async function verifyCoverageEntry (cookie: string, coverageId: string) {
  return await fetchWithAutoRefresh(`${process.env.REACT_APP_BE_URL}/verify-coverage-entry`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'coverage_id': coverageId,
    })
  })
}

export async function getClients (cookie: string, clientId: string) {
  return await fetch(`${process.env.REACT_APP_BE_URL}/change-current-client`, {
    method: "POST",
    headers: {
      'auth-token': cookie,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'new_client_id': clientId,
    })
  })
}




