import React, { useEffect, useState } from "react";
import {
  FilterWrapper,
  LinesWrapper,
  ProposalWrapper,
  TopBarContainer,
} from "./styles";
import { TopBarProps } from "./types";
import Button from "../Button";
import ListFilter from "../../assets/icons/ListFilter.svg";
import Sparkles from "../../assets/icons/Sparkles.svg";
import SparklesWhite from "../../assets/icons/Sparkles_white.svg";
import ChevronUpDown from "../../assets/icons/ChevronsUpDown.svg";
import ArrowLeft from "../../assets/icons/ArrowLeft.svg";
import ArrowLeftRightBlack from "../../assets/icons/ArrowLeftRightBlack.svg";
import ArrowLeftRight from "../../assets/icons/ArrowLeftRight.svg";
import Trash from "../../assets/icons/Trash2.svg";
import Download from "../../assets/icons/Download.svg";
import DownloadWhite from "../../assets/icons/DownloadWhite.svg";
import ConfirmableButton from "../ConfirmableButton";
import Dropdown from "../Dropdown";
import ChevronDown from "../../assets/icons/ChevronDown.svg";
import { Pages } from "../../LandingPage/types";

const TopBar: React.FC<TopBarProps> = ({
  handleFilter,
  handleCreateProposal,
  handleComparePolicies,
  onBack,
  document,
  download,
  deleteDoc,
  handleCollapseAll,
  handleExpandAll,
  lines,
  types,
  selectedLine,
  setSelectedLine,
  selectedType,
  setSelectedType,
  hideModals,
  page,
}) => {
  const [periods, setPeriods] = useState<string>('');
  const [generating, setGenerating] = useState<boolean>(false);

  useEffect(() => {
    if (generating) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval);
    }
  }, [generating]);

  useEffect(() => {
    setGenerating(page == Pages.COMPARISON && !!document && document.status != "analyzed" && document.status != "generated");
  }, [page, document])

  const mapLinesToDropdownOption = () => {
    const options = lines!.map((line) => {
      return { label: line, onClick: () => setSelectedLine!(line) };
    });

    options.unshift({
      label: "All Lines",
      onClick: () => setSelectedLine!("All Lines"),
    });

    return options;
  };

  const mapTypesToDropdownOption = () => {
    const options = types!.map((type) => {
      return { label: type, onClick: () => setSelectedType!(type) };
    });

    options.unshift({
      label: "All Types",
      onClick: () => setSelectedType!("All Types"),
    });

    return options;
  };

  return (
    <TopBarContainer>
      <FilterWrapper>
        {!onBack && handleFilter && (
          <Button
            iconLeft={ListFilter}
            text={"Filter"}
            handleClick={handleFilter}
          />
        )}
        {onBack && (
          <Button iconLeft={ArrowLeft} text={"Back"} handleClick={onBack} />
        )}
      </FilterWrapper>
      {types &&
        setSelectedType &&
        lines &&
        setSelectedLine &&
        page !== Pages.COMPARISON && (
          <LinesWrapper>
            <Dropdown
              id="comparisontour-step5"
              options={mapLinesToDropdownOption()}
              text={selectedLine}
              $grow
              iconRight={ChevronDown}
            />
            <Dropdown
              id="comparisontour-step6"
              options={mapTypesToDropdownOption()}
              text={selectedType}
              $grow
              iconRight={ChevronDown}
            />
          </LinesWrapper>
        )}
      <ProposalWrapper>
        {!document && (
          <>
            {handleCreateProposal && (
              <Button
                id="dashboardtour-step6"
                iconLeft={Sparkles}
                text={"Create Proposal"}
                handleClick={handleCreateProposal}
              />
            )}
            {handleComparePolicies && (
              <Button
                id="dashboardtour-step5"
                iconLeft={ArrowLeftRight}
                text={"Compare Policies"}
                handleClick={handleComparePolicies}
                $secondary
              />
            )}
          </>
        )}

        {document && (
          <>
            {download && page === Pages.COMPARISON && (
              <Button
                iconLeft={DownloadWhite}
                text={generating ? `Generating Excel${periods}` : "Download Excel"}
                handleClick={() => download(document)}
                disabled={generating}
                $secondary
              />
            )}
            {handleExpandAll && (
              <Button handleClick={() => handleExpandAll()} text="Expand All" />
            )}
            {handleCollapseAll && (
              <Button
                handleClick={() => handleCollapseAll()}
                text="Collapse All"
              />
            )}
            {handleCreateProposal && !hideModals && (
              <Button
                id="dashboardtour-step6"
                iconLeft={Sparkles}
                text={"Create Proposal"}
                handleClick={handleCreateProposal}
              />
            )}
            {handleComparePolicies && !hideModals && (
              <Button
                id="dashboardtour-step5"
                iconLeft={ArrowLeftRight}
                text={"Compare Policies"}
                handleClick={handleComparePolicies}
                $secondary
              />
            )}
            {download && page !== Pages.COMPARISON && (
              <Button
                iconLeft={Download}
                text={"Download"}
                handleClick={() => download(document)}
              />
            )}
            {deleteDoc && page !== Pages.COMPARISON && (
              <ConfirmableButton iconLeft={Trash} handleClick={deleteDoc} />
            )}
          </>
        )}
      </ProposalWrapper>
    </TopBarContainer>
  );
};

export default TopBar;
