import styled from "styled-components";
import sizing from "../../designSystem/sizing";

export const DocumentViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > :last-child {
    margin-left: ${sizing.SPACING.STANDARD};
  }
`;

export const DocumentContentWrapper = styled.div`
  flex: 1;
`;

export const PDFWrapper = styled.div`
  border: none;
  width: 100%;
  height: 230px;
`;
