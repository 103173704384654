import styled from "styled-components";
import sizing from "../../designSystem/sizing";

export const SubHeader = styled.h2`
    font-size: ${sizing.TEXT.H2};
    font-weight: 500;
    margin: 0;
`

export const Header = styled.h1`
    font-size: ${sizing.TEXT.H1};
    font-weight: 500;
    margin: 0;
`