import { useQuery } from 'react-query';
import clientService from '../services/clientService';
import { Client } from '../types/Client';

export const useClients = (cookie: string) => {
    return useQuery<Client[], Error>(
        ['clients', cookie],
        () => clientService.getClients(cookie)
    );
};
