import styled from "styled-components";
import sizing from "../../designSystem/sizing";
import colors from "../../designSystem/colors";

export const NumberedItemLabel = styled.span`
  margin-right: ${sizing.SPACING.STANDARD};
  color: ${({ color }) => (color ? color : colors.UI.TEXT.STANDARD)};
`;

export const NumberedItemContainer = styled.div`
  width: min-content;
  display: flex;
  white-space: nowrap;
  align-items: center;
`;
