import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";
import { SidebarStyleProps } from "./types";

export const SideBarContainer = styled.div<SidebarStyleProps>`
  border: 1px solid ${COLORS.UI.BORDER.STANDARD};
  padding: ${SIZING.SPACING.LARGE} 0;
  border-radius: ${SIZING.RADIUS.MEDIUM};
  width: 240px;
  flex-shrink: 0;
  margin-right: ${SIZING.SPACING.STANDARD};
  > p {
    margin-left: ${SIZING.SPACING.STANDARD};
    color: ${COLORS.UI.TEXT.GREY};
  }

  > * {
    margin-bottom: ${SIZING.SPACING.STANDARD};
    border-radius: 0;
  }

  left: -240px;
`;
