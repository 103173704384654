import { styled } from "styled-components";
import {
  ExpandableContainerProps,
  HeaderContainerProps,
  NumberRoundelProps,
} from "./types";
import sizing from "../../designSystem/sizing";
import colors from "../../designSystem/colors";

export const ExpandableContainer = styled.div<ExpandableContainerProps>`
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: ${sizing.RADIUS.MEDIUM};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  flex: 1;
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  ${({ $isOpen, $borderColor, $borderless }) =>
    $borderless
      ? "none"
      : $isOpen
      ? `border-bottom: 1px solid ${$borderColor};`
      : "border: none;"}
  border-radius: ${sizing.RADIUS.MEDIUM} ${sizing.RADIUS.MEDIUM} 0 0;
  padding: 0 ${sizing.SPACING.STANDARD};
  min-height: 60px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  min-width: 0;
  & > * {
    min-width: 0;
  }
`;

export const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  margin-left: ${sizing.SPACING.STANDARD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const Body = styled.div`
  display: block;
  padding: 0 ${sizing.SPACING.STANDARD} ${sizing.SPACING.STANDARD}
    ${sizing.SPACING.STANDARD};
  background-color: ${colors.UI.BACKGROUND.STANDARD};
  border-radius: 0 0 ${sizing.RADIUS.MEDIUM} ${sizing.RADIUS.MEDIUM};
`;

export const NumberRoundel = styled.p`
  color: ${colors.UI.TEXT.DANGER};
  border-radius: 5rem;
  background-color: ${colors.UI.BACKGROUND.STANDARD};
  padding: ${sizing.SPACING.SMALL};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;
