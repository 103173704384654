export const comparisonWT = [
    {
      element: '#comparisontour-step1',
      title: 'Congrats!',
      intro: 'You\'ve successfully run a comparison. Let\'s walk you through it!',
    },
    {
      element: '#comparisontour-step2',
      title: 'Summary',
      intro: 'Here\'s a summary of how similar the policies were!',
    },
    {
      element: '#comparisontour-step3',
      title: 'Data Points',
      intro: 'And here are the data points themselves. (Click on one for more info!)',
    },
    {
      element: '#comparisontour-step4',
      title: 'Filters',
      intro: 'Feel free to filter to see only the key differences (or similarities too).',
    },
    {
      element: '#comparisontour-step5',
      title: "Line Filter",
      intro: "Similarly, you can filter by coverage line.",
    },
    {
      element: '#comparisontour-step6',
      title: "Data Type Filter",
      intro: "Or even data point type. (Limits, Endorsements, Schedules, etc.)",
    },
    {
      element: '#comparisontour-step7',
      title: "Spreadsheet Download",
      intro: "At any point, you can download the comparison in spreadsheet format.",
    },
    {
      element: '#comparisontour-step8',
      title: "Final",
      intro: "We hope this was helpful! If you have any more questions, please click the bottom-right corner to chat with us now.",
    },
    // {
    //   element: '#comparisontour-step7',
    //   title: "Client List",
    //   intro: "A list of your accounts. When working with a new client, make sure to add them first!",
    // },
    // {
    //   element: '#dashboardtour-step8',
    //   title: "Final",
    //   intro: "That's it for now! Once your policy is done, we'll show you a couple more things.",
    // },
];