import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

import LandingPage from "./LandingPage";

const App = () => { 
  return (
    <MantineProvider theme={{ /* your theme settings */ }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <LandingPage />
        </BrowserRouter>
      </QueryClientProvider>
    </MantineProvider>
  )
};

export default App;
