import { RowType } from '../../components/DocumentWizardTable/types';
import { PillType } from "../../components/Pill/types";
import { DocumentType } from '../../components/Document';


export const filterAndMapToProposals = (inputArray: DocumentType[], filter: PillType): DocumentType[] => {
    const newArray = inputArray
        .filter(item => item.category === filter.toLocaleLowerCase())
        return newArray
    };


// export function mapDocumentObjectToDocument(documentObject: DocumentObject): DocumentType {
//   return {
//     title: documentObject.name || "Untitled", 
//     type: mapCategoryToPillType(documentObject.category), 
//     fileSize: "~200kb", // TODO add to api
//     date: new Date(documentObject.createdAt).toLocaleDateString(), 
//     status: mapStatusToPillType(documentObject.status), 
//     linkedDocuments: [] 
//   };
// }


// function mapCategoryToPillType(category: string): PillType {
//   switch (category.toLowerCase()) {
//     case "policy":
//       return PillType.POLICY
//     case "comparison":
//       return PillType.COMPARISON
//     case "proposal":
//       return PillType.PROPOSAL
//     default:
//       return PillType.DOCUMENT; 
//   }
// }


// function mapStatusToPillType(status: string): PillType {
//   switch (status.toLowerCase()) {
//     case "processed":
//       return PillType.PROCESSED; 
//     case "processing":
//       return PillType.PROCESSING;
//     case "analyzed":
//     default:
//       return PillType.DOCUMENT; 
//   }
// }