import styled from "styled-components";
import { IconProps } from "./types";
import SIZING from "../../designSystem/sizing";

export const Icon = styled.img<IconProps>`
  max-height: ${({ height }) =>
    height ? height : SIZING.ICON.HEIGHT.STANDARD};
  ${({ $backgroundColor }) =>
    $backgroundColor
      ? `
        background-color: ${$backgroundColor};
        border-radius: ${SIZING.RADIUS.SMALL};
        padding: 3px;
    `
      : ""}
`;
