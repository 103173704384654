import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import {
  highlightPlugin,
  RenderHighlightsProps,
} from "@react-pdf-viewer/highlight";
import { Trigger } from "@react-pdf-viewer/highlight";

import {
  Body,
  ExpandableContainer,
  HeaderContainer,
  HeaderText,
  NumberRoundel,
} from "./ExpandableArea/styles";
import ChevronDown from "../assets/icons/ChevronDown.svg";
import ChevronRight from "../assets/icons/ChevronRight.svg";
import { Icon } from "./Icon/styles";
import { ExpandableAreaProps } from "./ExpandableArea/types";
import Text from "./base/Text";
import { ResultWithReference } from "./PDFModal";
import {
  deleteMatchedPair,
  editCoverageEntry,
  editMatchedPair,
} from "../requests";
import { useCookies } from "react-cookie";
import Input from "./Input";
import Space from "./base/Space";
import Button from "./base/Button";
import {
  DocumentContentWrapper,
  DocumentViewWrapper,
  PDFWrapper,
} from "./PairItem/styles";
import ExpandableArea from "./ExpandableArea";
import colors from "../designSystem/colors";
import File from "../assets/icons/File3.svg";
import sizing from "../designSystem/sizing";
import { MatchedPair } from "./ComparisonModal";
import {
  ComparisonCaratContainer,
  CoverageTitle,
  DocumentComparisonContainer,
  DocumentTitleIcon,
  EditableFields,
  HeadedContent,
  HeadedContentContainer,
  HeadedContentContent,
  HeadedContentContentWrapper,
  HeadedContentHeader,
  HeadedContentHeaderWrapper,
  LabelledEditableWrapper,
} from "../pages/Comparison/styles";
import FileIcon from "../assets/icons/File3.svg";
import EditableText from "./EditableText";
import ComparedValues from "./ComparedValues";

const PairItem = ({
  matchedPairId,
  matchedPairs,
  setMatchedPairs,
  firstResult,
  secondResult,
  firstName,
  secondName,
  firstPresignedUrl,
  secondPresignedUrl,
  similar,
  onClick,
  selected,
  onDoubleClick,
  setRefresh,
  onScheduleClick,
  header,
  iconLeft,
  $backgroundColor,
  $borderColor,
  number,
  onOpen,
  toggle,
  index,
}: {
  matchedPairId: string;
  firstResult: ResultWithReference;
  secondResult: ResultWithReference;
  firstName: string;
  secondName: string;
  firstPresignedUrl: string;
  secondPresignedUrl: string;
  similar: boolean;
  onClick: () => void;
  selected: boolean;
  onDoubleClick: () => void;
  setRefresh: (b: boolean) => void;
  onScheduleClick: (r: ResultWithReference) => void;
  matchedPairs: MatchedPair[];
  setMatchedPairs: (p: MatchedPair[]) => void;
  $backgroundColor: string;
  $borderColor: string;
  iconLeft?: string;
  header: string;
  number?: string;
  onOpen?: boolean;
  toggle?: (bool: boolean, key: number) => void;
  index?: number;
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [currentCoverageName, setCurrentCoverageName] = useState<string>("");
  const [matches, setMatches] = useState<boolean>(similar);
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [firstDocumentLoaded, setFirstDocumentLoaded] =
    useState<boolean>(false);
  const [secondDocumentLoaded, setSecondDocumentLoaded] =
    useState<boolean>(false);

  const [firstValue, setFirstValue] = useState<string>("");
  const [firstSublimit, setFirstSublimit] = useState<string>("");
  const [firstRetention, setFirstRetention] = useState<string>("");
  const [firstDescription, setFirstDescription] = useState<string>("");
  const [firstSchedule, setFirstSchedule] = useState<string>("");

  const [secondValue, setSecondValue] = useState<string>("");
  const [secondSublimit, setSecondSublimit] = useState<string>("");
  const [secondRetention, setSecondRetention] = useState<string>("");
  const [secondDescription, setSecondDescription] = useState<string>("");
  const [secondSchedule, setSecondSchedule] = useState<string>("");

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    if (unsavedChanges) {
      onSaveEdit();
      setUnsavedChanges(false);
    }
  }, [unsavedChanges]);

  const [isOpen, setIsOpen] = useState<boolean>(onOpen || false);

  const handleClick = () => {
    const newValue = !isOpen;
    toggle && index != null && toggle(newValue, index);
    setIsOpen(newValue);
  };

  useEffect(() => {
    if (onOpen === undefined) return;

    if (onOpen !== isOpen) {
      setIsOpen(onOpen);
    }
  }, [onOpen]);

  const resetValues = () => {
    setFirstValue(firstResult?.keys["value"] ?? "");
    setFirstSublimit(firstResult?.keys["limit"] ?? "");
    setFirstRetention(firstResult?.keys["retention"] ?? "");
    setFirstDescription(firstResult?.keys["description"] ?? "");
    setFirstSchedule(firstResult?.keys["schedule"] ?? "");

    setSecondValue(secondResult?.keys["value"] ?? "");
    setSecondSublimit(secondResult?.keys["limit"] ?? "");
    setSecondRetention(secondResult?.keys["retention"] ?? "");
    setSecondDescription(secondResult?.keys["description"] ?? "");
    setSecondSchedule(secondResult?.keys["schedule"] ?? "");
  };

  useEffect(() => {
    setBackgroundColor(
      matches
        ? selected
          ? "#c5e1a5"
          : isHovered
          ? "#d1e7b7"
          : "#dcedc9"
        : selected
        ? "#ffa3a6"
        : isHovered
        ? "#ffb5b7"
        : "#ffc8c9"
    );
  }, [matches, selected, isHovered]);

  useEffect(() => {
    setMatches(similar);
  }, [similar]);

  useEffect(() => {
    setCurrentCoverageName(
      firstResult?.coverageName || secondResult?.coverageName || ""
    );
  }, [firstResult?.coverageName, secondResult?.coverageName]);

  useEffect(() => {
    resetValues();
  }, [firstResult?.keys, secondResult?.keys]);

  const onSaveEdit = async () => {
    if (firstResult.coverageType == "generic") {
      setMatchedPairs(
        matchedPairs.map((p) => {
          if (p.id == matchedPairId)
            return {
              ...p,
              firstVal: { ...firstResult, keys: { value: firstValue } },
              secondVal: { ...secondResult, keys: { value: secondValue } },
            };
          return p;
        })
      );
      editCoverageEntry(
        cookies["user-id"],
        firstResult.id,
        { value: firstValue },
        currentCoverageName
      );
      editCoverageEntry(
        cookies["user-id"],
        secondResult.id,
        { value: secondValue },
        currentCoverageName
      );
    } else if (firstResult.coverageType == "coverage") {
      setMatchedPairs(
        matchedPairs.map((p) => {
          if (p.id == matchedPairId)
            return {
              ...p,
              firstVal: {
                ...firstResult,
                keys: { limit: firstSublimit, retention: firstRetention },
              },
              secondVal: {
                ...secondResult,
                keys: { limit: secondSublimit, retention: secondRetention },
              },
            };
          return p;
        })
      );
      editCoverageEntry(
        cookies["user-id"],
        firstResult.id,
        { limit: firstSublimit, retention: firstRetention },
        currentCoverageName
      );
      editCoverageEntry(
        cookies["user-id"],
        secondResult.id,
        { limit: secondSublimit, retention: secondRetention },
        currentCoverageName
      );
    }
    setEditing(false);
  };

  const onDelete = async () => {
    await deleteMatchedPair(cookies["user-id"], matchedPairId);

    setRefresh(true);
    setEditing(false);
  };

  const handleSwitch = async () => {
    setMatchedPairs(
      matchedPairs.map((p) => {
        if (p.id == matchedPairId) return { ...p, similar: !matches };
        return p;
      })
    );
    editMatchedPair(cookies["user-id"], matchedPairId, !matches);
    setMatches(!matches);
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {[firstResult].map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const secondRenderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {[secondResult].map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const secondHighlightPluginInstance = highlightPlugin({
    renderHighlights: secondRenderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;
  const { jumpToHighlightArea: secondJumpToHighlightArea } =
    secondHighlightPluginInstance;

  useEffect(() => {
    if (firstDocumentLoaded) {
      jumpToHighlightArea(firstResult.highlightArea);
      setFirstDocumentLoaded(false);
    }
  }, [firstDocumentLoaded]);

  useEffect(() => {
    if (secondDocumentLoaded) {
      secondJumpToHighlightArea(secondResult.highlightArea);
      setSecondDocumentLoaded(false);
    }
  }, [secondDocumentLoaded]);

  return (
    <ExpandableContainer
      $borderColor={colors.UI.BORDER.STANDARD}
      $backgroundColor={colors.UI.BACKGROUND.STANDARD}
    >
      <HeaderContainer
        $borderColor={colors.UI.BORDER.STANDARD}
        $borderless
        onClick={handleClick}
        $isOpen={isOpen}
      >
        <CoverageTitle>
          {iconLeft && (
            <Icon
              src={iconLeft}
              $backgroundColor={$backgroundColor}
              alt="Expandable Heading Icon"
            />
          )}
          {number && !iconLeft && <NumberRoundel>{number}</NumberRoundel>}
          {!editing && <HeaderText>{header}</HeaderText>}
        </CoverageTitle>
        <ComparisonCaratContainer>
          {(firstValue || secondValue) && !isOpen && (
            <ComparedValues
              firstValue={firstValue || "Not Mentioned"}
              secondValue={secondValue || "Not Mentioned"}
            />
          )}
          {(firstSublimit || secondSublimit) && !isOpen && (
            <ComparedValues
              firstValue={firstSublimit || "Not Mentioned"}
              secondValue={secondSublimit || "Not Mentioned"}
            />
          )}
          <Icon
            src={isOpen ? ChevronDown : ChevronRight}
            alt={`${isOpen ? "Open" : "Closed"} Icon`}
          />
        </ComparisonCaratContainer>
      </HeaderContainer>
      {isOpen && (
        <Body>
          <DocumentViewWrapper>
            <DocumentContentWrapper>
              <HeadedContentContainer>
                <HeadedContent>
                  <HeadedContentHeaderWrapper>
                    <HeadedContentContent>
                      <DocumentTitleIcon $displayMargin={!!firstValue}>
                        <Icon src={FileIcon} alt="document icon" />
                        {firstName}
                      </DocumentTitleIcon>
                      <EditableFields>
                        {firstValue && (
                          <EditableText
                            defaultValue={firstValue}
                            onSubmitChange={(val) => {
                              setFirstValue(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                        {(firstSublimit || secondSublimit) && (
                          <LabelledEditableWrapper>
                            <span>Limit:</span>
                            <EditableText
                              defaultValue={firstSublimit}
                              onSubmitChange={(val) => {
                                setFirstSublimit(val);
                                setUnsavedChanges(true);
                              }}
                              placeholder="Not Mentioned"
                              $alwaysBorder
                              $grow
                            ></EditableText>
                          </LabelledEditableWrapper>
                        )}
                        {(firstRetention || secondRetention) && (
                          <LabelledEditableWrapper>
                            <span>Retention:</span>
                            <EditableText
                              defaultValue={firstRetention}
                              onSubmitChange={(val) => {
                                setFirstRetention(val);
                                setUnsavedChanges(true);
                              }}
                              placeholder="Not Mentioned"
                              $alwaysBorder
                              $grow
                            ></EditableText>
                          </LabelledEditableWrapper>
                        )}
                        {firstDescription && (
                          <EditableText
                            defaultValue={firstDescription}
                            onSubmitChange={(val) => {
                              setFirstDescription(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                        {firstSchedule && (
                          <EditableText
                            defaultValue={firstSchedule}
                            onSubmitChange={(val) => {
                              setFirstSchedule(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                      </EditableFields>
                    </HeadedContentContent>
                  </HeadedContentHeaderWrapper>
                  <HeadedContentContentWrapper>
                    {" "}
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      {firstPresignedUrl && (
                        <PDFWrapper>
                          <Viewer
                            fileUrl={firstPresignedUrl}
                            plugins={[highlightPluginInstance]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            onDocumentLoad={() => setFirstDocumentLoaded(true)}
                          />
                        </PDFWrapper>
                      )}
                    </Worker>
                  </HeadedContentContentWrapper>
                </HeadedContent>
              </HeadedContentContainer>
            </DocumentContentWrapper>
            <DocumentContentWrapper>
              <HeadedContentContainer>
                <HeadedContent>
                  <HeadedContentHeaderWrapper>
                    <HeadedContentContent>
                      <DocumentTitleIcon $displayMargin={!!firstValue}>
                        <Icon src={FileIcon} alt="document icon" />
                        {secondName}
                      </DocumentTitleIcon>
                      <EditableFields>
                        {secondValue && (
                          <EditableText
                            defaultValue={secondValue}
                            onSubmitChange={(val) => {
                              setSecondValue(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                        {secondSublimit && (
                          <LabelledEditableWrapper>
                            <span>Limit:</span>
                            <EditableText
                              defaultValue={secondSublimit}
                              onSubmitChange={(val) => {
                                setSecondSublimit(val);
                                setUnsavedChanges(true);
                              }}
                              placeholder="Not Mentioned"
                              $alwaysBorder
                              $grow
                            ></EditableText>
                          </LabelledEditableWrapper>
                        )}
                        {secondRetention && (
                          <LabelledEditableWrapper>
                            <span>Retention:</span>
                            <EditableText
                              defaultValue={secondRetention}
                              onSubmitChange={(val) => {
                                setSecondRetention(val);
                                setUnsavedChanges(true);
                              }}
                              placeholder="Not Mentioned"
                              $alwaysBorder
                              $grow
                            ></EditableText>
                          </LabelledEditableWrapper>
                        )}
                        {secondDescription && (
                          <EditableText
                            defaultValue={secondDescription}
                            onSubmitChange={(val) => {
                              setSecondDescription(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                        {secondSchedule && (
                          <EditableText
                            defaultValue={secondSchedule}
                            onSubmitChange={(val) => {
                              setSecondSchedule(val);
                              setUnsavedChanges(true);
                            }}
                            $alwaysBorder
                            $grow
                          ></EditableText>
                        )}
                      </EditableFields>
                    </HeadedContentContent>
                  </HeadedContentHeaderWrapper>
                  <HeadedContentContentWrapper>
                    {" "}
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      {secondPresignedUrl && (
                        <PDFWrapper>
                          <Viewer
                            fileUrl={secondPresignedUrl}
                            plugins={[secondHighlightPluginInstance]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            onDocumentLoad={() => setSecondDocumentLoaded(true)}
                          />
                        </PDFWrapper>
                      )}
                    </Worker>
                  </HeadedContentContentWrapper>
                </HeadedContent>
              </HeadedContentContainer>
            </DocumentContentWrapper>
          </DocumentViewWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: sizing.SPACING.STANDARD,
            }}
          >
            <Text size={16}>Matches?</Text>
            <Switch checked={matches} onChange={handleSwitch} />
          </div>
        </Body>
      )}
    </ExpandableContainer>
  );
};

export default PairItem;
