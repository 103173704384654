import React from 'react';
import { ModalBackground, ModalContainer, ModalWrapper } from './styles';
import { ModalProps } from './types';
import Text from '../Text';

const Modal: React.FC<ModalProps> = ({ children, onClick }) => {

    return (<>
            <ModalBackground onClick={onClick}/>
            <ModalContainer>
                <ModalWrapper>
                    {children}
                </ModalWrapper>
            </ModalContainer>
        </>
    );
}

export default Modal;