

export interface DocumentObject {
    associatedDocumentId: string | null;
    category: string;
    clientId: string;
    createdAt: string;
    id: string | undefined;
    instanceId: string;
    lines: string[];
    link: string;
    name: string;
    percentComplete: number | undefined;
    percentVerified: number;
    status: string;
}

export enum Pages {
    DOCUMENTS = "Documents",
    DOCUMENT = "Document",
    LINES = "Lines",
    COMPARISON = "COMPARISON",
}

export interface PresignData {
    url: string;
    fields: {
      [key: string]: string | Blob; // Specifying that fields can be string or Blob
    };
  }
  