import React, { useEffect, useState } from "react";
import { DropdownProps } from "./types";
import Button from "../Button";
import { DropdownContainer } from "./styles";
import Menu from "../Menu";

const Dropdown: React.FC<DropdownProps> = ({
  id,
  text,
  icon,
  $secondary,
  options,
  $alignRight,
  openStateCallback,
  value,
  $grow,
  iconRight,
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(value || false);

  useEffect(() => {
    value && setMenuOpen(value);
  }, [value]);

  return (
    <DropdownContainer
      id={id}
      onClick={(e) => e.stopPropagation()}
      $grow={$grow}
    >
      <Button
        text={text}
        iconLeft={icon}
        handleClick={(e) => {
          e && e.stopPropagation();
          setMenuOpen(!menuOpen);
          openStateCallback && openStateCallback(!menuOpen);
        }}
        $secondary={$secondary}
        $grow={$grow}
        iconRight={iconRight}
      />
      {menuOpen && options && (
        <Menu
          options={options}
          setMenuOpen={setMenuOpen}
          $alignRight={$alignRight}
          $grow={$grow}
        />
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
