import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import { PageContentWrapperStyleProps } from "./types";

export const PageContentWrapper = styled.div<PageContentWrapperStyleProps>`
    padding: ${SIZING.SPACING.STANDARD};
    display: flex;
    flex-direction: column;
    height: calc(100vh - 174px); // to prevent overlapping the bottom of the screen;
    overflow-y: auto;
    position: absolute;
    width: ${({ sidebarOpen }) => sidebarOpen ? "calc(100% - 24px)" : "calc(100vw + 254px - 23px)"};
    left: ${({ sidebarOpen }) => sidebarOpen ? "0" : "-254px"};

    transition: width 250ms ease, left 250ms ease;
`;

export const PageContentHorizontalWrapper = styled.div`
    display: flex;
    height: 100%;
`;