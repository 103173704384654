import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "./ModalOld";
import Text from "./base/Text";
import Button from "./base/Button";
import { createClient } from "../requests";
import { useCookies } from "react-cookie";
import Space from "./base/Space";

const CreateClientModal = ({ setModalOpen, modalOpen, setClientId, setClients, retrieveAllClients }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  setClientId: (s: string) => void,
  setClients: any,
  retrieveAllClients: () => void,
}) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [name, setName] = useState<string>("");

  const createClientFunc = async () => {
    const resp = await createClient(cookies['user-id'], name);
    console.log("CLIENT CREATED");
    console.log(resp);
    // await setClients(prev => [...prev, { name: name, id: resp.body["client_id"] }])
    await retrieveAllClients();
    setClientId(resp.body["client_id"]);
    localStorage.setItem('selectedClientId', resp.body["client_id"])
    setModalOpen(false);
    navigate("/");
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="createClientModal" extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "30%",
      height: "40%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
    }}>
      <Text weight={600} size={40} align="center">
        Create a New Client
      </Text>
      <Space px={20} />
      <Text weight={600} size={20}>
        Client Name
      </Text>
      <input
        type="text"
        value={name}
        placeholder="Embargo Ltd."
        onChange={(e) => setName(e.currentTarget.value)}
        style={{
          width: "230px",
          borderRadius: "8px",
          fontSize: "20px",
          padding: "10px",
          border: "1px solid #475569",
          outline: "none",
          fontFamily: "Assistant, sans-serif"
        }}
      />
      <Space px={10} />
      <Space px={10} />
      <div style={{
        position: "absolute",
        bottom: "20px",
        right: "20px"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px"
        }}>
          <Button width="60px" height="40px" color="#ffffff" border="1px solid #787878" onClick={() => setModalOpen(false)}>
            <Text size={14} color="#787878">
              Cancel
            </Text>
          </Button>
          <Button greyed={!name} width="100px" height="40px" color="#ffffff" border="2px solid #000000" onClick={createClientFunc}>
            <Text size={14} weight={600}>
              Create Client
            </Text>
          </Button>
        </div>
      </div>
    </Modal>
  )
};

export default CreateClientModal;