import React from 'react';
import { MenuProps } from './types';
import { MenuContainer, MenuOption, MenuOptionLabel } from './styles';
import { Icon } from '../Icon/styles';

const Menu: React.FC<MenuProps> = ({ options, setMenuOpen, $alignRight, $grow }) => {
    return(
        <MenuContainer $alignRight={!!$alignRight} $grow={!!$grow}>
            {options.map((option, index) => 
                <MenuOption onClick={() => {
                    option.onClick();
                    setMenuOpen && setMenuOpen(false);
                }} key={index}>
                    {option.icon && <Icon src={option.icon} alt={`${option.label}_icon`} />}
                    <MenuOptionLabel>{option.label}</MenuOptionLabel>
                </MenuOption>)
            }
        </MenuContainer>
    );
}

export default Menu;